import { DownloadOutlined, CalendarOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Avatar, Button, Spin, List, Space } from "antd";
import AppPagination from "../../organisms/AppTable/AppPagination";
import ValidatePermissions from "../../../helpers/ValidatePermissions";

const ListTemplate = ({
  header,
  data,
  className = "md:max-w-[600px] md:w-full lg:max-w-[920px] 2xl:max-w-[calc(100vw-120px)] 2xl:px-10",
  currentPage,
  total,
  pageSize = 10,
  onClickRow = () => {},
  handlePageChange = () => {},
  hasPagination = true,
}) => {
  const [loading, setLoading] = useState({});

  const onDownloadFile = (item) => {
    // eslint-disable-next-line no-console

    setLoading((prevLoading) => ({ ...prevLoading, [item.key]: true }));
    setTimeout(() => {
      setLoading((prevLoading) => ({ ...prevLoading, [item.key]: false }));
    }, 2000);
  };

  const authorTitle = ({ author, title }) => (
    <Space direction="vertical">
      <span className=" text-[13px]">{author}</span>
      {title}
    </Space>
  );

  return (
    <div className={`max-w-[calc(100vw-20px)]${className} `}>
      <List
        itemLayout="vertical"
        header={header && header}
        dataSource={data}
        className="font-Poppins"
        renderItem={(item, rowIndex) => (
          <List.Item
            key={item.key}
            actions={[
              <Space>
                <CalendarOutlined />
                {item.date}
              </Space>,
              <ValidatePermissions
                container={false}
                func="oneButton"
                permission="download"
                modulePermission="researchpublished"
              >
                <Button
                  className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none"
                  icon={!loading[item.key] ? <DownloadOutlined /> : <Spin />}
                  onClick={() => onDownloadFile(item)}
                />
              </ValidatePermissions>,
            ]}
            extra={
              <img
                width={272}
                style={{ maxHeight: "201px" }}
                alt="Logo"
                src={item.imgUrl}
              />
            }
          >
            <List.Item.Meta
              onClick={() => onClickRow(item, rowIndex)}
              avatar={<Avatar src={item.avatar} />}
              title={authorTitle({ author: item.author, title: item.title })}
              description={item.description}
            />
            {item.content}
          </List.Item>
        )}
      />

      {hasPagination ? (
        <AppPagination
          page={currentPage}
          total={total}
          pageSize={pageSize}
          onChange={() => {
            handlePageChange();
            setLoading((prev) => ({ ...prev, list: true }));
          }}
        />
      ) : null}
    </div>
  );
};
export default ListTemplate;
