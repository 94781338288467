/* eslint-disable no-underscore-dangle */
const findMaxDepth = (groups, depth = 0) => {
  if (!groups || groups.length === 0) return depth;
  return Math.max(
    ...groups.map((group) => findMaxDepth(group.child_groups, depth + 1))
  );
};

const addLevels = (groups, level = 0) => {
  if (!groups || groups.length === 0) return [];

  groups.forEach((group) => {
    const updatedGroup = { ...group, level }; // Create a new object with the level property
    addLevels(updatedGroup.child_groups, level + 1); // Recursively add levels to child groups
    Object.assign(group, updatedGroup); // Update the original group object
  });
  return groups;
};

function addLevelsAndParents(groups, level = 0, parentsArray = []) {
  if (!groups || groups.length === 0) return [];

  groups.forEach((group) => {
    const newParentsArray = [...parentsArray, group?._id];
    const updatedGroup = { ...group, level, parentsArray: newParentsArray };
    addLevelsAndParents(updatedGroup.child_groups, level + 1, newParentsArray);
    Object.assign(group, updatedGroup);
  });
  return groups;
}

export { findMaxDepth, addLevels, addLevelsAndParents };
