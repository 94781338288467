import { Navigate } from "react-router-dom";
import token from "../api/token";

const NotRequireAuth = ({ children }) => {
  const refreshToken = token.getRefreshToken();
  const isAuthenticated = !!refreshToken;

  return isAuthenticated ? <Navigate to="/" replace /> : children;
};

export default NotRequireAuth;
