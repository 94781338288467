
// App.js
import { Form, message } from "antd";
import React ,{ useState,useEffect}from "react";
import { useNavigate } from "react-router-dom";
import AppTitle from "../../atoms/Title/AppTitle";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import VariableForm from "../../organisms/VariableForm/VariableForm";
import ROUTES from "../../../constant/router";
// import Step from "../../molecules/Step/Step";


const VariableFormTemplate = ({ listColumns, listAgregation, isEdit, dataEdit }) => {


  // const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [title, setTitle] = useState("Crear Variable de Indicador");
  // const [columns, setColumns] = useState(listColumns);
  
  const handlerRegister = () => {
    // const itemCol = form.getFieldValue("column");
    // // eliminar el item de la lista de columnas
    // console.log("se registró", itemCol);
    // const cols = columns.filter((item) => item.value !== itemCol);
    // setColumns(cols);
    message.success("Se registró correctamente");
    form.resetFields();
    return navigate(ROUTES.GENERAL.INDICATORS_VARIABLE);

  };

  const handlerEdit = () => {
    message.success("Se editó correctamente");
    return navigate(ROUTES.GENERAL.INDICATORS_VARIABLE);
  };

  useEffect(() => {
    if (isEdit) {
      setTitle("Editar Variable de Indicador");
      form.setFieldsValue({
        name: dataEdit.name,
        fontColumn: dataEdit.fontColumn,
        aggregation: dataEdit.aggregation,
      });
    }
  }, [isEdit]);

  return (
    <Form
      form={form}
      className="font-Poppins "
      scrollToFirstError
    >

      <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">

        <div className="w-3/4 bg-white_custom p-6 rounded-[45px]  border border-colorBorder/25 shadow-2xl">
          {/* <div className=" mb-2 grid justify-items-center px-6 !important">
            <Step current={0} items={items} />
          </div> */}
          <div className=" mb-2 grid justify-items-center ">

            <AppTitle
              title={title}
              level={2}
              className="font-bold text-lightBlue"
            />

          </div>
          <div className="flex justify-center items-center mb-2">

            <VariableForm listColumns={listColumns} listAgregation={listAgregation} isEdit={isEdit} />

          </div>
          <Form.Item>
            <div className="flex justify-center text-center ">
              <SubmitButton
                nameButton={isEdit ? "Editar" : "Guardar"}
                // loading={loading}
                form={form}
                onClick={isEdit ? handlerEdit : handlerRegister}
              />
            </div>
          </Form.Item>

        </div>



      </div>
    </Form>
  )
}
export default VariableFormTemplate;

