const ROUTES = {
  HOME: {
    LANDINGPAGE: "/landing_page",
    RECOVERY: "/recovery_password/:token",
    USER_CREATE: "/register",
  },
  AUTH: {
    LOGIN: "/login",
    RECOVERY: "/recovery_password/:token",
    USER_CREATE: "/register",
  },
  GENERAL: {
    HOME: "/",
    USER_EDIT: "/user/edit",
    REPORT: "/report",
    STATEMENT: "/statement",
    STATEMENT_INDICATORS: "/statement_indicators",
    INVESTIGATION: "/published_research",
    INVESTIGATION_ADMIN: "/research",
    INVESTIGATION_INFO: "/research/:key",
    DOCUMENTS: "/documents",
    // DOCUMENT_UPLOAD: "/document/Upload",
    DOCUMENT_INFO: "/document/:key",
    // DOCUMENT_DESCRIPTION : "document/:key/description",
    // DOCUMENT_REGISTER: "document/:key/register",
    // DOCUMENT_FILTER: "document/:key/register/filter",
    // DOCUMENT_REGISTER_DESCRIPTION: "document/:key/register/description",
    // DOCUMENT_EDIT: "document/:key/edit/:column",
    INDICATORS: "/indicators",
    INDICATORS_CREATE: "/indicator/register",
    INDICATORS_EDIT: "/indicator/edit/:id",
    INDICATORS_VARIABLE_CREATE: "/indicator/variable/register",
    INDICATORS_VARIABLE_EDIT: "/indicator/variable/edit/:id",
    INDICATORS_VARIABLE: "/indicator/variable",
    USERS_LIST: "/users/list",
    APPLICATIONS: "/applications",
    HISTORY: "/history",
    COMPARATIVE_ANALYSIS: "/comparative_analysis",
    COMPARATIVE_ANALYSIS_DETAIL: "/comparative_analysis/:id",
  },
};

export default ROUTES;
