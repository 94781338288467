/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import useApi from "../../../api/useApi";
import document from "../../../api/document";
import AppButton from "../../atoms/Buttons/AppButton";
import { useAuth } from "../../../contexts/AuthContext";

const IframeView = ({
  frameWidth = "100%",
  frameHeight = "1000",
  frameTitle,
  urlView = null,
  className,
}) => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const getUrl = useApi(document.getUrlPandasDoc);
  const validateUrl = useApi(document.validUrl);
  const [url, setUrl] = useState("");
  const [embeddable, setEmbeddable] = useState(false);
  const { setMessage } = useAuth();

  useEffect(() => {
    if (urlView) {
      setUrl(urlView);

      validateUrl.request({ url: urlView });
      // setLoading(false);
    } else {
      // setLoading(true);
      getUrl.request(params?.key);
    }
    setLoading(true);
  }, []);
  useEffect(() => {
    if (urlView) {
      setUrl(urlView);
      validateUrl.request({ url: urlView });
      setLoading(true);
    }
  }, [urlView]);

  useEffect(() => {
    if (getUrl.data) {
      const url_pandas = getUrl.data.data.download_url;
      setEmbeddable(true);
      setUrl(url_pandas);
      setEmbeddable(true);
      setLoading(false);
    }
  }, [getUrl.data]);

  useEffect(() => {
    if (validateUrl.data) {
      const aux = validateUrl.data;

      if (aux.isValid) {
        setEmbeddable(true);
      } else {
        setMessage({
          type: "error",
          title: "error",
          description: "Esta URL no permite ser embebida en la página.",
        });
        setEmbeddable(false);
      }
      setLoading(false);
    }
  }, [validateUrl.data]);

  const handleOpenUrl = () => {
    window.open(url, "_blank");
  };
  return (
    <div>
      {!loading ? (
        <div>
          {embeddable ? (
            <div>
              <iframe
                style={{ border: "0" }}
                src={url}
                width={frameWidth}
                height={frameHeight}
                title={frameTitle}
                className={className}
              />
              <div className="flex justify-end items-end pt-4 p">
                <AppButton
                  type="primary"
                  onClick={handleOpenUrl}
                  title="Abrir en una nueva pestaña"
                  className="bg-lightBlue2 "
                  size="small"
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center ">
              <AppButton
                type="primary"
                onClick={handleOpenUrl}
                title="Abrir en una nueva pestaña"
                className="bg-lightBlue2 flex justify-end items-end"
                size="small"
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default IframeView;
