import axios from "axios";
import Cookies from "js-cookie";
import Config from "../config";

const saveToken = (token) => sessionStorage.setItem("token", token);

const saveRefreshToken = (refreshToken, exDays) =>
  Cookies.set("refreshToken", refreshToken, {
    expires: exDays,
    secure: true,
  });
/* Cookies.set("refreshToken", refreshToken, {
      expires: exDays,
      secure: true,
      sameSite: "strict",
    }); */
/* const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`; */

// document.cookie = `refreshToken = ${refreshToken}; Secure; SameSite=strict;${expires};path=/;`;

// Function to retrieve token from local storage
const getToken = () => sessionStorage.getItem("token");

const getRefreshToken = () => Cookies.get("refreshToken");
/*   const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.trim().split("="));
    console.log("🚀 ~ getRefreshToken ~ cookies:", cookies)
    const refreshTokenCookie = cookies.find(
      (cookie) => cookie[0] === "refreshToken"
    );
    return refreshTokenCookie ? refreshTokenCookie[1] : null; */

// Function to remove token from local storage
const removeToken = () => {
  sessionStorage.removeItem("token");
};

const removeRefreshToken = () => Cookies.remove("refreshToken");
// Cookies.remove("refreshToken", { secure: true, sameSite: "strict" });
/*     document.cookie =
      "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; */

const refreshingToken = async () => {
  const url = `${Config.urlBackend}/auth/refresh-token`;
  const refreshToken = getRefreshToken();
  const dataRequest = axios.post(url, { refreshToken });
  let accessToken;
  await dataRequest
    .then((res) => {
      if (res.status === 200 && res.data.accessToken) {
        accessToken = res.data.accessToken;
        saveToken(accessToken);
      } else {
        removeRefreshToken();
      }
    })
    .catch((/* err */) => {
      // if(err.request.response.includes("Invalid refresh token"))
      removeRefreshToken();
    });
  return accessToken
};

export default {
  saveToken,
  getToken,
  saveRefreshToken,
  getRefreshToken,
  removeToken,
  removeRefreshToken,
  refreshingToken,
};
