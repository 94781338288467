
import { Layout } from "antd";
import VariableListTemplate from "../../components/templates/VariableTemplate/ListVariableTemplate";


const { Content } = Layout;


const Variables = () => (
  <Layout>
    <Content>
      <VariableListTemplate />
    </Content>
  </Layout>

)

export default Variables;

