// App.js
import { Form, message } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppTitle from "../../atoms/Title/AppTitle";
import IndicatorDescripForm from "../../organisms/IndicatorForm/IndicatorForm";
import SubmitButton from "../../atoms/Buttons/SubmitButton";
import ROUTES from "../../../constant/router";
// import Step from "../../molecules/Step/Step";

const IndicatorFormTemplate = ({
  listType,
  listVariable,
  isEdit,
  dataInitial,
}) => {
  // const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [title, setTitle] = useState("Crear Indicador");
  // Las columnas de la tabla
  const navigate = useNavigate();
  // const items = [
  //   {
  //     title: "",

  //   },
  //   {
  //     title: "",

  //   },
  // ];
  const handlerRegister = () => {
    // const itemCol = form.getFieldValue("column");
    // eliminar el item de la lista de columnas
    // console.log("se registró", itemCol);
    // const cols = columns.filter((item) => item.value !== itemCol);
    // setColumns(cols);
    message.success("Se registró correctamente");
    form.resetFields();
    return navigate(ROUTES.GENERAL.INDICATORS);
  };

  const handlerEdit = () => {
    // eslint-disable-next-line no-console
    console.log("se editó");
    message.success("Se editó correctamente");
    return navigate(ROUTES.GENERAL.INDICATORS);
  };

  useEffect(() => {
    if (isEdit) {
      setTitle("Editar indicador");
      form.setFieldsValue({
        name: dataInitial.name,
        type: dataInitial.type,
        variable1: dataInitial.variable1,
        variable2: dataInitial.variable2,
        variable3: dataInitial.variable3,
        variable4: dataInitial.variable4,
        denominator: dataInitial.denominator,
        numerator: dataInitial.numerator,
      });
    }
  }, [isEdit]);

  return (
    <Form form={form} className="font-Poppins " scrollToFirstError>
      <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">
        <div className="w-3/4 bg-white_custom pt-6 pb-2 px-2 mb-4 rounded-[54px] border border-colorBorder/25 shadow-2xl">
          {/* <div className=" mb-2 grid justify-items-center px-6 !important">
            <Step current={1} items={items} />
          </div> */}
          <div className=" mb-2 grid justify-items-center ">
            <AppTitle
              title={title}
              level={2}
              className="font-bold text-lightBlue"
            />
          </div>
          <div className="flex justify-center items-center mb-2">
            <IndicatorDescripForm
              listType={listType}
              listVariable={listVariable}
            />
          </div>
          <Form.Item>
            <div className="flex justify-center text-center ">
              <SubmitButton
                nameButton={isEdit ? "Editar" : "Guardar"}
                // loading={loading}
                form={form}
                onClick={isEdit ? handlerEdit : handlerRegister}
              />
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
export default IndicatorFormTemplate;
