import React, { useEffect, useState } from "react";
import { Upload, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import AppButton from "../../atoms/Buttons/AppButton";

const UploadExcel = ({ title, settingFileList }) => {
  const [fileList, setFileList] = useState([]);
  const [flag, setFlag] = useState(false);

  const props = {
    name: "file",
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isValid = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
        "application/vnd.ms-excel",
        "application/pdf",
        /* "text/csv", */
      ].includes(file.type);
      if (!isValid) {
        message.error(`${file.name} no es un archivo válido`);
        return true;
      }
      setFileList([file]);
      setFlag(true);
      return false;
    },
    fileList,
  };

  const handleChange = ({ fileList: newFileList }) => {
    settingFileList(newFileList);
    if (
      newFileList.length > 0 &&
      [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
        "application/vnd.ms-excel",
        "application/pdf",
        /* "text/csv", */
      ].includes(newFileList[0].type)
    ) {
      const fileStatus = { ...newFileList[0], status: "uploading" };
      setFileList([fileStatus]);
    }
  };

  /* const uploadButton = (
    <>
      <UploadOutlined className="text-xl" />
      <strong className="text-lg ml-2 font-medium">Examinar</strong>
    </>
  ); */

  // Save file in backend
  useEffect(() => {
    if (flag)
      if (fileList && fileList.length > 0) {
        if (fileList[0].originFileObj && fileList[0].status === "uploading") {
          const fileStatus = { ...fileList[0], status: "done" };
          setFileList([fileStatus]);
        }
      }
  }, [fileList]);

  return (
    <Upload
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={handleChange}
      defaultFileList={[...fileList]}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, application/pdf" /* ,
        application/vnd.ms-excel,text/csv" */
    >
      <AppButton
        type=" primary"
        shape="round"
        size="small"
        className="bg-lightBlue2 w-[302px]"
        title={title}
        icon={<DownloadOutlined rotate={270} />}
        style={{ fontSize: "20px" }}
      />
    </Upload>
  );
};
export default UploadExcel;
