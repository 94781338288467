/* eslint-disable arrow-body-style */
import { useEffect, useState } from "react";
import { Form, Row, Col } from "antd";
// import { useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
// import mapboxgl from "mapbox-gl";
import HomeForm from "../../organisms/HomeForm/HomeForm";
import MapCard from "../../organisms/MapCard/MapCard";
// import dataFaker from "../../../assets/Json/dataFaker.json";
import useApi from "../../../api/useApi";
import MapRequest from "../../../api/MapRequest";
import DocumentRequest from "../../../api/DocumentRequest";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import transformToCheckArray from "../../../helpers/transformToCheckArray";
import transformToMap from "../../../helpers/transformToMap";
import { useAuth } from "../../../contexts/AuthContext";



const HomeTemplate = ({
  handleItemZone,
  handleItemEvent,
  zonesSelected,
  eventsSelected,
}) => {
  const deptoCode = 52;
  const [mapData, setMapData] = useState([]);
  const requestDataMap = useApi(MapRequest.getDataMap);
  const [municipalitiesList, setMunicipalitiesList] = useState([]);
  const [listEvents, setListEvents] = useState([]);
  const [listHealthEvents, setListHealthEvents] = useState([]);
  const [form] = Form.useForm();
  const {
    request: getMunicipalities,
    data: municipalities,
    setData: setDataMunicipalities,
  } = useApi(DocumentRequest.getMunicipalities);
  const {
    request: getAllEvents,
    data: events,
    setData: setEvents,
  } = useApi(MapRequest.getAllEvents);
  const { setMessage } = useAuth();
  useEffect(() => {
    getMunicipalities(deptoCode);
    getAllEvents();
    // getEvents("seguridad");
  }, []);

  useEffect(() => {
    if (municipalities) {
      const aux = transformToSelectArray(
        municipalities,
        "municipio",
        "c_digo_dane_del_municipio"
      );
      const sortedData = [...aux].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setMunicipalitiesList(sortedData);
      setDataMunicipalities();


    }

    if (Array.isArray(events)) {
      const violence = events.filter((event) => event.eventCategoryId === "violencia");
      const health = events.filter((event) => event.eventCategoryId === "salud");
      const auxViolence = transformToSelectArray(
        violence,
        "name",
        "id"
      );
      const sortedEvents = [...auxViolence].sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      const auxHealth = transformToCheckArray(
        health,
        "name",
        "id",
        "id"
      );
      const sortedHealth = [...auxHealth].sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      setListEvents(sortedEvents);
      setListHealthEvents(sortedHealth);


      // console.log("salud lista", auxHealth);


      setEvents();
    }

  }, [municipalities, events]);

  useEffect(() => {
    if (requestDataMap.data) {
      const dataTransformed = transformToMap(requestDataMap.data);
      if (dataTransformed.features.length === 0) {
        setMessage({
          type: "warning",
          title: "Sin resultados",
          description: "No se encontraron eventos con los filtros seleccionados.",
        });
      }
      setMapData(dataTransformed);

    }
  }, [requestDataMap.data]);

  const validateForm = (values) => {

    // Validar que al menos uno de los tipos de eventos esté seleccionado
    if (!(values.violence && values.violence.length > 0) && !(values.health && values.health.length > 0)) {
      setMessage({
        type: "error",
        title: "Error",
        description: "Por favor, selecciona al menos un tipo de evento.",
      });


      return false;

    }
    // form.validateFields(["dateRange", "zones"]);;
    return true;

  };



  const onclick = async (values) => {
    if (validateForm(values)) {
      try {
        const cityList = values.zones.map(item => item.value.replace(".", ""));
        const dataFilter = {
          date: { startDate: values.dateRange[0].format("YYYY-MM-DD"), endDate: values.dateRange[1].format("YYYY-MM-DD") },
          events: [
            ...(values.violence && values.violence.length > 0 ? values.violence.map(item => item.value): []),
            ...(values.health && values.health.length > 0 ? values.health : [])
          ],
          municipalities: cityList,
        };
        
        await requestDataMap.request(dataFilter);
      } catch (error) { /* empty */ }
    }
  };




  return (
    <Row className="justify-between">
      <Col xs={24} md={12} lg={12} xl={16}>
        <MapCard data={mapData} />
      </Col>
      <Col xs={24} md={8} lg={12} xl={8}>
        <Form
          form={form}
          name="home-form"

        >
          <HomeForm
            onSelectItemZone={handleItemZone}
            onSelectItemEvent={handleItemEvent}
            zones={zonesSelected}
            events={eventsSelected}
            municipalities={municipalitiesList}
            violenceList={listEvents}
            healthList={listHealthEvents}
            form={form}
            onClick={() => onclick(form.getFieldsValue())}
          />
        </Form>
      </Col>
    </Row >
  );
};

export default HomeTemplate;
