import { Layout } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoginTemplate from "../../components/templates/LoginTemplate/LoginTemplate";
import auth from "../../api/auth";
import useApi from "../../api/useApi";
import { useAuth } from "../../contexts/AuthContext";

const { Content } = Layout;

const RecoveryPage = () => {
  const [loading, setLoading] = useState(false);
  const changePsw = useApi(auth.changePswNoAuth);
  const { setMessage } = useAuth();
  const { token } = useParams();
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    const { password, confirmPassword } = e;
    if (password === confirmPassword) {
      changePsw.request({ token, newPassword: confirmPassword });
      setLoading(true);
    } else {
      setMessage({
        type: "error",
        title: "Error",
        description: "Las contraseñas no coinciden. Inténtelo de nuevo.",
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (changePsw.data) {
      setLoading(false);
      navigate("/login");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La contraseña fue cambiada correctamente.",
      });
      changePsw.setData();
    }
  }, [changePsw.data]);

  useEffect(() => {
    if (changePsw.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error en el servidor al intentar cambiar la contraseña. Inténtelo de nuevo mas tarde.",
        code: changePsw.error,
      });
      setLoading(false);
    }
  }, [changePsw.error]);

  const handleBackButton = () => navigate("/login");
  return (
    <Layout>
      <Content>
        <LoginTemplate
          loading={loading}
          onSubmitForm={handleFormSubmit}
          handleBackButton={handleBackButton}
          isRecovery
        />
      </Content>
    </Layout>
  );
};

export default RecoveryPage;
