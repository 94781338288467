import React from "react";
import { Form, InputNumber, ConfigProvider } from "antd";
import AppImg from "../../atoms/Img/AppImg";
import FlagColombia from "../../../assets/image/co.svg";
import { inputPhoneStyle } from "../../../assets/theme/themeConfig";
// import "../../../assets/css/stylesInputNumber.css";

/* eslint-disable react/jsx-props-no-spreading */

const FormPhone = ({ name, label, className = "", placeholder, labelCol, ...props }) => {
  const combinedClassName = `font-Poppins font-light text-black_custom  ${className} !important`;
  return (
    <ConfigProvider
      theme={{
        components: {
          "InputNumber": inputPhoneStyle
        },
      }}
    >
      <Form.Item
        labelCol={labelCol}
        colon={false}
        name={name}
        label={label ? <span className="font-Poppins font-light  text-color_text">{label}</span> : null}
        rules={[
          {
            required: true,
            message: "Por favor ingrese su número de teléfono",
          },
          {
            pattern: /^[0-9]{10}$/,
            message: "El número de teléfono debe tener exactamente 10 dígitos y contener solo números del 0 al 9",
          },
        ]}
        
        className={combinedClassName}
        {...props}
      >
        <InputNumber
          className=" text-black_custom font-Poppins font-light  rounded-full  !important"
          prefix="+57"
          addonBefore={<AppImg src={FlagColombia} alt="Colombia"
            className="w-5 max-w-none !important" />}
          
        />
      </Form.Item>
    </ConfigProvider>
  )
};

export default FormPhone;