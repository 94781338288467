/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */

/* eslint-disable arrow-body-style */
const transformToTree = (data) => {
  // if (!Array.isArray(data)) return [];
  if (!data.data) return [];

  const dataTransformed = data.data.map((groupItem) => {
    const { group, groupIndicators } = groupItem;

    return {
      title: group?.name,
      value: group?._id,
      key: group?._id,
      checkable: false,
      selectable: false,
      level: 0,
      children: [...transformGroupIndicators(groupIndicators, group?._id)].sort(
        (a, b) => a.title.localeCompare(b.title)
      ), // Transforma los indicadores del grupo y añade hijos
    };
  });
  return dataTransformed;
};

const transformGroupIndicators = (groupIndicators, parent_id) => {
  if (Array.isArray(groupIndicators)) {
    return groupIndicators.map((item) => ({
      title: item?.name,
      value: item?._id,
      key: item?._id,
      parent: item?._id,
      group: parent_id,
      level: 1,
      isLeaf: false,
      children: [...transformSources(item?.formula, item?._id)].sort((a, b) =>
        a.title.localeCompare(b.title)
      ), // Agrega los hijos transformados (fuentes)
    }));
  }
  return [];
};

const transformSources = (sources, parent_id) => {
  return sources.sources.map((source) => ({
    title: source?.name,
    value: source?._id,
    key: source?._id,
    parent: parent_id,
    level: 2,
    isLeaf: true, // Establece la propiedad isLeaf a true para las fuentes
  }));
};

export default transformToTree;
