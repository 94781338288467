import api from ".";
import client_img from "./client_img";

const createUser = (data) => {
  const url = "users/signup/";
  return api.post(url, data);
};

const getInfoUser = (email) => {
  const url = `/users/${email}`;
  return api.get(url);
};

const getAllUsers = (nameUser = "", limit = 10) => {
  const url = `/users/?name=${nameUser}&limit=${limit}&offset=0`;
  return api.get(url);
};

const updateUser = (data) => {
  const url = "/users/update";
  return api.put(url, data);
};

const uploadImage = (file,fileName,fileExtension,size) => {
  const url = `/users/upload-image?fileName=${fileName}&docType=user&fileExtension=${fileExtension}&size=${size}`;
  return client_img.post(url, file);
};



const uploadPdf = (id,file,fileName,fileExtension,size, source) => {
  const url = `/users/upload-pdf?fileName=${fileName}&docType=user&fileExtension=${fileExtension}&size=${size}&source=${source}&id=${id}`;
  return client_img.post(url, file);
};

export default 
{ 
  createUser, 
  getInfoUser, 
  getAllUsers,
  updateUser,
  uploadImage,
  uploadPdf,
};
