// App.js
import { Form } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import DocumentForm from "../../organisms/DocumentForm/DocumentForm";
import SubmitButtonNotValidate from "../../atoms/Buttons/SubmitButtonNotValidate";

const DocumentFormTemplate = ({
  listColumns,
  listCodification,
  action,
  listMunicipalities,
  hasTitle = false,
  dataDescription,
  handlerSubmit,
  loading,
  WebSource,
  urlSource,
}) => {
  const [form] = Form.useForm();
  const dayFormat = "DD/MM/YYYY";
  const [isWebSource, setIsWebSource] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (dataDescription) {
      const { name, description, locationData } = dataDescription;
      let municipalityColumn = "";
      let municipalityCode = "";
      let municipality = [];
      let dateColumn = "";
      let dateRange = "";
      let joinDates = "";
      const urlWebSource = dataDescription?.urlWebSource || null;
      const keyWords = dataDescription?.keyWords || [];
      const cutDate = dataDescription?.cutOffDate
        ? dataDescription?.cutOffDate.trim()
        : null;
      const cutOffDate = cutDate ? dayjs(cutDate, dayFormat) : null;
      if (locationData) {
        ({
          municipalityColumn,
          municipalityCode,
          municipality,
          dateColumn,
          dateRange,
        } = locationData);
        if (dateRange) {
          // const auxRangeDate = dateRange.split("-");
          const startDate = dateRange.trim();
          joinDates = dayjs(startDate, dayFormat);
        }
      }
      const auxData = {
        name,
        description,
        municipalityColumn: municipalityColumn || "0",
        municipalityCode: municipalityCode || "",
        municipality: municipality || [],
        dateColumn: dateColumn || "0",
        dateRange: joinDates || "",
        urlWebSource,
        keyWords,
        cutOffDate,
      };
      form.setFieldsValue(auxData);

      if (dataDescription.fileExtension === "web") {
        setIsWebSource(true);
      } else {
        setIsWebSource(false);
      }
    } else if (WebSource) {
      setIsWebSource(true);
    } else {
      setIsWebSource(false);
    }
  }, [dataDescription, WebSource]);

  useEffect(() => {
    if (urlSource) {
      form.setFieldsValue({ urlWebSource: urlSource });
    }
  }, [urlSource]);

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      const tags = form.getFieldValue("keyWords");
      const newTag = inputValue.trim();
      if (newTag && !tags.includes(newTag)) {
        const auxTags = [...tags];
        auxTags.push(newTag);
        setInputValue("");
        form.setFieldValue("keyWords", auxTags);
      }
    }
  };

  const codificationInputValidator = () => [
    {
      validator: async (_, value) => {
        const valueCode = form.getFieldValue("municipalityCode");
        if (!value && valueCode === "0") {
          return Promise.reject(
            new Error(
              "Este campo es requerido si 'Codificación Municipio' está vacío o en Ninguno"
            )
          );
        }
        return Promise.resolve();
      },
    },
    {
      required: true,
      message: "",
    },
  ];
  const onSubmit = (values) => {
    handlerSubmit(values);
    form.resetFields();
  };
 

  return (
    <Form
      form={form}
      onFinish={(values) => onSubmit(values)}
      className="font-Poppins "
      scrollToFirstError
    >
      {hasTitle ? (
        <p className="my-5 text-lg text-lightBlue2 font-bold">
          Descripción del Documento
        </p>
      ) : null}
      <div className="flex flex-col items-center ">
        <div className=" p-2">
          <div className="flex justify-center items-center ">
            <DocumentForm
              listColumns={listColumns}
              listMunicipalities={listMunicipalities}
              listCodification={listCodification}
              // validatorMunicipality={municipalityInputValidator}
              // validatorDate={dateInputValidator}
              validatorCode={codificationInputValidator}
              action={action}
              dataDescription={dataDescription}
              WebSource={isWebSource}
              setInputValue={setInputValue}
              searchValue={inputValue}
              handleInputKeyDown={handleInputKeyDown}
            />
          </div>
          <Form.Item>
            <div className="flex justify-center text-center">
              <SubmitButtonNotValidate
                nameButton="Guardar"
                form={form}
                loading={loading}
                htmlType="submit"
              />
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
export default DocumentFormTemplate;
