import { useState, useEffect } from "react";
import Tour from "reactour";
import { Spin, Button } from "antd";
import { useNavigate } from "react-router-dom";
import ListTemplate from "../../components/templates/ListTemplate";
import investigationRequest from "../../api/investigationRequest";
import ROUTES from "../../constant/router";

const Investigation = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState({});
  const [metadata, setMetaData] = useState({
    totalRecords: 10,
    actualPage: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [isTourOpen, setIsTourOpen] = useState(false);
  const navigate = useNavigate();

  const getDataTable = () => {
    const datainvestigacion = investigationRequest.getResearch();
    setData(datainvestigacion);
    setLoading((prev) => ({ ...prev, list: false }));
  };

  useEffect(() => {
    setLoading((prev) => ({ ...prev, list: true }));
    getDataTable();
  }, []);

  const onClickRow = (item) => {
    const path = ROUTES.GENERAL.INVESTIGATION_INFO.replace(":key", item.key);
    navigate(path);
  };

  const handlePageChange = (actualPage) => {
    setMetaData((prev) => ({ ...prev, actualPage }));
  };

  const steps = [
    {
      selector: ".title-views",
      content: "This is the title of the investigations page.",
    },
    {
      selector: ".list-template",
      content: "Here you can see the list of investigations.",
    },
    {
      selector: ".pagination",
      content: "Use this pagination to navigate through the investigations.",
    },
  ];

  return (
    <div className="flex flex-col justify-center items-center ">
      <h4 className="title-views my-5">Investigaciones</h4>
      <div className="w-9/12">
        <Button
          type="primary"
          onClick={() => setIsTourOpen(true)}
          className="mb-4"
        >
          Start Tour
        </Button>
      </div>
      <div className="w-9/12 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
        {loading.list ? (
          <Spin />
        ) : (
          <ListTemplate
            className="list-template"
            data={data}
            onClickRow={onClickRow}
            currentPage={metadata.actualPage}
            total={metadata.totalRecords}
            pageSize={metadata.pageSize}
            handlePageChange={handlePageChange}
            hasPagination
          />
        )}
      </div>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </div>
  );
};

export default Investigation;
