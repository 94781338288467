/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dropdown,
  Space,
  Spin,
  Typography,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  EllipsisOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import TableTemplate from "../TableTemplate";
import DataPreviewFilter from "./DataPreviewFilter";
import ModalConfirm from "../../molecules/Modal/ModalConfirm";
import ColumnTemplate from "../ColumnTemplate/ColumnTemplate";
import AppModal from "../../molecules/Modal/Modal";
import DataPreviewInformation from "./DataPreviewInformation";
import useApi from "../../../api/useApi";
import document from "../../../api/document";
import isObject from "../../../helpers/isObject";
// import formatSize from "../../../helpers/formatSize";
import { useAuth } from "../../../contexts/AuthContext";
import DocumentRequest from "../../../api/DocumentRequest";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import transformToDateFormat from "../../../helpers/transformToDateFormat";
import transformToTree from "../../../helpers/transformToTree";
import transformToTreeSelectRegions from "../../../helpers/transformToTreeSelectRegions";
import AssociateIndicatorForm from "../../organisms/AssociateIndicatorForm/AssociateIndicatorForm";
import IndicatorRequest from "../../../api/indicator";
import IframeView from "../../organisms/Iframeview/IframeView";
import ValidatePermissions from "../../../helpers/ValidatePermissions";
import PdfViewer from "../../organisms/PdfView/pdfView";
import downloadFile from "../../../helpers/downloadFile";
import DataProfiling from "../DataProfiling/DataProfiling";
import processColumns from "../../../helpers/processColumns";
import SheetFormTemplate from "../SheetFormTemplate/SheetFormTemplate";
import transformColumns from "../../../helpers/transformColumns";
import SelectSheet from "../../molecules/SelectSheet/SelectSheet";
import ROUTES from "../../../constant/router";



const { Text } = Typography;

const listCodification = [
  {
    id: 1,
    value: "1",
    label: "DANE COMPLETO",
  },
  {
    id: 2,
    value: "2",
    label: "DANE SIN PREFIJO",
  },
];


const DataPreviewTemplate = ({ dataInfo }) => {
  const params = useParams(); // Extracción correcta del key

  const listActiveFilter = [
    {
      id: 1,
      name: "Filtro 1",
      originColumn: "VIGENCIA",
      filterType: "Contiene",
      text: "20",
    },
    {
      id: 2,
      name: "Filtro 2",
      originColumn: "SEXO",
      filterType: "Igual a",
      text: "Mujer",
    },
    {
      id: 3,
      name: "Filtro 3",
      originColumn: "ETNICA",
      filterType: "No contiene",
      text: "Ninguna",
    },
  ];

  const [webSourceActive, setWebSourceActive] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeButton, setActiveButton] = useState("information");
  // const [originColumn, setOriginColumn] = useState("Ninguna");
  const [actionFilterView, setActionFilterView] = useState("view");
  const [metadata, setMetaData] = useState({
    totalRecords: 10,
    actualPage: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [dataDoc, setDataDoc] = useState({ columns: [], data: [] });
  const [loading, setLoading] = useState({
    table: false,
    infoDoc: false,
    description: false,
    reloadTable: false,
  });
  const [listColumns, setListColumns] = useState([]);
  const [columnsSelect, setColumnsSelect] = useState([]);
  const [dataDescription, setDataDescription] = useState([]);
  const [dataModalDescription, setDataModalDescription] = useState();
  const [dataDesColumn, setDataDecColumn] = useState({});
  const [data, setData] = useState();
  const [listMunicipalities, setListMunicipalities] = useState([]);
  const [infoDoc, setInfoDoc] = useState({});
  const [clickedDropdown, setClickedDropdown] = useState(false);
  const [columnNameSelected, setColumnNameSelected] = useState("");
  const [modalIndicator, setModalIndicator] = useState(false);
  const [modalActiveConfirm, setModalActiveConfirm] = useState(false);
  const [listGroupIndicator, setListGroupIndicator] = useState([]);
  const [urlView, setUrlView] = useState(null);
  const getDataDoc = useApi(document.getDataDocument);
  const updateDescriptionColum = useApi(document.updateDescriptionColum);
  const updateDescriptionDoc = useApi(document.updateDescriptionDoc);
  const getDescriptionDoc = useApi(document.getDescriptionDoc);
  const getParentDoc = useApi(document.getDescriptionDoc);
  const updateColumSensitive = useApi(document.updateColumSensitive);
  const isFavoriteDocument = useApi(document.UpdateFavoriteDocument);
  // const getMunicipalities = useApi(DocumentRequest.getMunicipalities);
  const getMunicipalityForRegion = useApi(
    DocumentRequest.getMunicipalitiesSubRegions
  );
  const getIndicators = useApi(IndicatorRequest.getIndicatorsTaxonomies);
  const createPostulation = useApi(IndicatorRequest.createPostulateIndicator);
  const deletePostulation = useApi(IndicatorRequest.deletePostulateIndicator);
  const downloadPdf = useApi(document.downloadPdf);
  const downloadDocument = useApi(document.downloadDocument);
  const { setMessage } = useAuth();
  const [isOwner, setIsOwner] = useState(false);
  const [isNoFile, setIsNoFile] = useState(false);
  const [pdfSourceActive, setPdfSourceActive] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [columnPostulation, setColumnPostulation] = useState();
  const [columnEditPostulation, setColumnEditPostulation] = useState();
  const [listSheet, setListSheet] = useState([]);
  const navigate = useNavigate();
  // const [dataParent, setDataParent] = useState([]);

  // const [starColor, setStarColor] = useState("grey");

  // useEffect(() => {
  //   if (isWebSource) {
  //     console.log("isWebSource", isWebSource);
  //     setWebSourceActive(true);
  //   } else {
  //     setWebSourceActive(false);
  //   }
  // }, [isWebSource]);

  // useEffect(() => {
  //   if (isNoFileSource) {
  //     setIsNoFile(true);
  //     if (!isWebSource) {
  //       setPdfSourceActive(true);
  //     } else {
  //       setPdfSourceActive(false);
  //     }
  //   } else {
  //     setIsNoFile(false);
  //   }
  // }, [isNoFileSource,isWebSource]);

  const reloadData = () => {
    setLoading((prev) => ({ ...prev, table: true }));
    getDataDoc.request(params?.key, metadata.pageSize, metadata.actualPage);
  };
  const getInfoDoc = () => {
    getDescriptionDoc.request(params?.key);
  };

  useEffect(() => {
    setLoading((prev) => ({ ...prev, infoDoc: true }));
    // getInfoDoc();
    getMunicipalityForRegion.request();
    // getMunicipalities.request(52);
    getIndicators.request();
    getDescriptionDoc.request(params?.key);

  }, []);

  useEffect(() => {
    if (dataInfo) {
      const auxData = dataInfo;
      setIsOwner(auxData.isOwner);
      if (auxData.fileExtension === ".pdf") {
        setPdfSourceActive(true);
        setUrlView(auxData.urlPdf);
        setIsNoFile(true);
      }
      if (auxData.fileExtension === "web") {
        setWebSourceActive(true);
        setUrlView(auxData.urlWebSource);
        setIsNoFile(true);
      }
      if (auxData.fileExtension === ".xlsx" || auxData.fileExtension === ".csv" || auxData.fileExtension === ".xls") {
        if (auxData.isChild) {
          getParentDoc.request(auxData.parentId);
        }
        if (auxData.multipleSheets) {

          if (auxData.urlPages.length > 0) {
            const aux = transformToSelectArray(auxData.urlPages, "name", "_id", false);
            setListSheet(aux);
            // getDescriptionDoc.request(auxData.urlPages[0]._id);
            const path = ROUTES.GENERAL.DOCUMENT_INFO.replace(":key", auxData.urlPages[0]._id);
            navigate(path);
          }

        }
        // } else {
        //   getInfoDoc();
        // }
      }
      getDescriptionDoc.request(params?.key);
    }
  }, [dataInfo]);

  const initialLoad = useRef(true);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      reloadData();
    }
  }, [metadata.actualPage]);

  // const openModal = () => setShowModal(true);

  const closeModal = () => {
    setShowModal(false);
    setClickedDropdown(false);
  };

  const activeSection = (nameButton) => setActiveButton(nameButton);

  /*   const items = [
    {
      label: "Editar descripción",
      key: "0",
    },
    {
      label: "Agregar nuevo filtro",
      key: "1",
    },
  ]; */

  /*   const handleClickItemDropdown = (nameColumn, { key }) => {
    console.log(
      "🚀 ~ handleClickItemDropdown ~ dataDescription:",
      dataDescription
    );
    setOriginColumn(nameColumn);
    setClickedDropdown(true);
    if (key === "1") {
      setActionFilterView("add");
      activeSection("filter");
    }
    if (key === "0") {
      const auxValues = dataDescription.filter(
        (val) => val.name === nameColumn
      );
      setDataDecColumn(auxValues[0]);
      openModal();
    }
  }; */

  const handlerSubmitDescription = (val) => {
    const {
      name,
      description,
      municipalityColumn,
      dateColumn,
      headerStart,
      headerEnd,
      dataStart,
      dataEnd,
    } = val;
    let munColumnAux = municipalityColumn || null;
    let dateColumnAux = dateColumn || null;
    const municipality = val?.municipality || [];
    let municipalityCode = null;
    const dateRange = val?.dateRange
      ? transformToDateFormat(val?.dateRange)
      : null;
    let urlWebSource = val?.urlWebSource || null;
    const keyWords = val?.keyWords || [];
    const cutOffDate = val?.cutOffDate
      ? transformToDateFormat(val?.cutOffDate)
      : null;
    const headerSize = [headerStart, headerEnd];
    const dataSize = dataStart && dataEnd ? [dataStart, dataEnd] : [];

    if (municipalityColumn === "0") {
      munColumnAux = null;
    } else {
      municipalityCode = val?.municipalityCode || null;
    }

    if (dateColumn === "0") {
      dateColumnAux = null;
    }
    if (dataModalDescription.fileExtension === "web") {
      urlWebSource = dataModalDescription.urlWebSource;
    }
    let auxData = {
      name,
      description,
      urlWebSource,
      keyWords,
      cutOffDate,
      locationData: {
        municipalityColumn: munColumnAux,
        municipalityCode,
        municipality,
        dateColumn: dateColumnAux,
        dateRange,
      },

    };
    if (
      dataModalDescription.fileExtension === ".xlsx" ||
      dataModalDescription.fileExtension === ".csv" ||
      dataModalDescription.fileExtension === ".xls"
    ) {
      auxData = {
        ...auxData,
        headerSize,
        dataSize,
      };
      setLoading((prev) => ({ ...prev, table: true }));
    }
    updateDescriptionDoc.request(params?.key, auxData);
    setLoading((prev) => ({ ...prev, description: true }));


  };

  const onDownloadDocument = () => {
    if (infoDoc.ext === ".pdf") {
      downloadPdf.request(params?.key);
    } else {
      downloadDocument.request(params?.key);
    }
  };

  const onFavoriteDocument = () => {
    isFavoriteDocument.request(params?.key, { isFavorite: !isFavorite });
  };

  /* const renderDropdown = (name) => (
    <div className="flex w-full text-center justify-between">
      <div className="flex flex-col justify-center w-full">
        <p className="self-center">{name}</p>
      </div>
      <Dropdown
        menu={{
          items,
          onClick: (e) => handleClickItemDropdown(name, e),
        }}
        trigger={["click"]}
      >
        <button
          className="flex flex-col"
          onClick={(e) => e.preventDefault()}
          type="button"
          aria-label="Save"
        >
          <div className="">
            <EllipsisOutlined
              rotate={90}
              style={{ fontSize: "17px", transform: "scale(1.5, 1  )" }}
            />
          </div>
        </button>
      </Dropdown>
    </div>
  ); */


  const onEditDescColumn = (record) => {
    setModalIndicator(false);
    setShowModal(true);
    setDataDecColumn(record);
  };

  const onHandleSaveDesc = (values) => {
    const auxValues = { ...values, name: dataDesColumn?.name };
    updateDescriptionColum.request(params?.key, auxValues);
    setLoading((prev) => ({ ...prev, description: true }));
  };

  const onChangeIsSensitive = (e, record) => {
    const auxData = dataDescription.map((item) => {
      if (item.name === record.name) {
        return { ...item, isSensitiveData: e.target.checked };
      }
      return item;
    });
    setDataDescription(auxData);
    const dataAux = {
      name: record.name,
      isSensitiveData: e.target.checked,
    };
    updateColumSensitive.request(params?.key, dataAux);
    setLoading((prev) => ({ ...prev, description: true }));
  };

  const onAssociatedIndicator = (record) => {
    setColumnNameSelected(record.name);
    setModalIndicator(true);
    setShowModal(true);
    setColumnPostulation(record);
  };
  const onEditIndicator = (record) => {
    setColumnNameSelected(record.name);
    const aux = record.idExtractionState.filter(
      (item) => item.state === "proposed" || item.state === "rejected"
    );
    const initialValues = {
      indicator: aux.map((item) =>
        item.type === "indicator"
          ? item.idIndicatorType._id
          : item.idCalculationSource._id
      ),
      parentValues: aux.map((item) => ({
        parent:
          item.type === "indicator"
            ? item.idIndicatorType.id_indicator_group
            : item.idIndicatorType._id,
        children:
          item.type === "indicator"
            ? item.idIndicatorType._id
            : item.idCalculationSource._id,
        type: item.type,
      })),
    };

    setColumnPostulation(record);
    setColumnEditPostulation(initialValues);
    setModalIndicator(true);
    setShowModal(true);
  };

  const handleAssociatedIndicator = (values, parentValue, isEdit) => {
    if (values) {
      let filteredParentValue = parentValue;
      let removedValues = [];

      if (isEdit) {
        // Filtrar parentValue para eliminar valores que ya estén en initialValues
        const initialIndicatorIds = columnEditPostulation.indicator;
        filteredParentValue = parentValue.filter(
          (item) => !initialIndicatorIds.includes(item.children)
        );
        // Obtener registros que están en initialValues pero no en parentValue
        removedValues = columnEditPostulation.parentValues.filter(
          (item) =>
            !parentValue.some((newItem) => newItem.children === item.children)
        );
        if (removedValues.length > 0) {
          const auxPos = columnPostulation.idExtractionState
            .filter((item) =>
              removedValues.some(
                (newItem) =>
                  (item.idIndicatorType &&
                    newItem.children === item.idIndicatorType._id) ||
                  (item.idCalculationSource &&
                    newItem.children === item.idCalculationSource._id)
              )
            )
            .filter(
              (item) => item.state === "proposed" || item.state === "rejected"
            )
            .map((item) => item._id);

          const dataDelete = {
            fileId: params?.key,
            ids: auxPos,
            columnName: columnPostulation.name,
          };
          deletePostulation.request(dataDelete);
        }
      }
      if (filteredParentValue.length > 0) {
        const request = {
          fileId: params?.key,
          columnName: columnNameSelected,
          listIndicators: filteredParentValue,
          // idIndicatorType: parentValue,
          // idCalculationSource: values.indicator,
          state: "proposed",
          // eslint-disable-next-line object-shorthand
        };
        // console.log("request", request);
        createPostulation.request(request);
      }
      setLoading((prev) => ({ ...prev, description: true }));
      closeModal();
      setModalIndicator(false);
    }
    setColumnNameSelected();
    setColumnPostulation();
    setColumnEditPostulation();
  };

  const handleDeletePostulation = (record) => {
    setColumnPostulation(record);
    setModalActiveConfirm(true);
  };

  const EliminarCloseModal = () => {
    setModalActiveConfirm(false);

    const idPostulations = columnPostulation.idExtractionState
      .filter((item) => item.state === "proposed" || item.state === "rejected")
      .map((item) => item._id);

    const auxData = {
      fileId: params?.key,
      ids: idPostulations,
      columnName: columnPostulation.name,
    };
    deletePostulation.request(auxData);
    setColumnPostulation();
  };
  const closeConfirmModal = () => {
    setModalActiveConfirm(false);
  };

  const RenderModalDeletePostulations = () => {
    if (modalActiveConfirm) {
      return (
        <div>
          <ModalConfirm
            centered
            okText="Eliminar"
            handleCancel={closeConfirmModal}
            handleOK={EliminarCloseModal}
            closeModal={closeConfirmModal}
            modalActive={modalActiveConfirm}
            text="¿Está seguro de que desea eliminar las postulaciones de esta columna?, Se eliminarán solamente las postulaciones que no hayan sido aceptadas."
          />
        </div>
      );
    }
    return null;
  };

  const handleClickItemDropdown = (e, record) => {
    if (e.key === "1") {
      onEditIndicator(record);
    }
    if (e.key === "2") {
      handleDeletePostulation(record);
    }
  };

  const handleDescriptionClicked = () => {

    const ListColumnsDescription = [
      {
        title: "Nombre Columna",
        dataIndex: "name",
        key: "name",
        align: "center",
        width: 400,
      },
      {
        title: "Tipo de Dato",
        dataIndex: "dataType",
        key: "dataType",
        align: "center",
        width: 100,
      },
      {
        title: "Dato Sensible",
        dataIndex: "isSensitiveData",
        key: "isSensitiveData",
        align: "center",
        render: (text, record) => {
          if (isOwner) {
            return (
              <div className="flex justify-center">
                <Checkbox
                  key={record.name}
                  checked={text}
                  onChange={(e) => onChangeIsSensitive(e, record)}
                />
              </div>
            );
          }
          return (
            <div className="flex justify-center">
              <Checkbox key={record.name} checked={text} disabled />
            </div>
          );
        },
      },
      {
        title: "Descripción",
        dataIndex: "description",
        key: "description",
        align: "center",
        width: 400,
      },
      {
        title: "Indicador Asociado",
        dataIndex: "idExtractionState",
        key: "idExtractionState",
        align: "center",
        // width: 400,
        // eslint-disable-next-line arrow-body-style
        render: (text, record) => {
          const items = [
            {
              key: 1,
              label: "Editar",
            },
            {
              key: 2,
              label: "Eliminar",
            },
          ];

          if (!text || text.length === 0) {
            if (isOwner) {
              return (
                <Button
                  className="text-lightBlue2 shadow-xl hover:text-lightBlue hover:shadow-none my-2"
                  icon={<PlusOutlined />}
                  onClick={() => onAssociatedIndicator(record)}
                  shape="circle"
                  size="small"
                />
              );
            }
          } else {
            return (
              <div className="flex space-x-2 justify-center w-[200px]">
                <Space direction="vertical">
                  {text.map((item, index) => (
                    <span
                      key={
                        item.idIndicatorType._id +
                        (item.idCalculationSource
                          ? `_${item.idCalculationSource._id}`
                          : `_${index}`)
                      }
                    >
                      {item.idIndicatorType.name}{" "}
                      {item.idCalculationSource
                        ? ` -> ${item.idCalculationSource.name}`
                        : ""}
                    </span>
                  ))}
                </Space>
                {isOwner ? (
                  <Dropdown
                    menu={{
                      items,
                      onClick: (e) => handleClickItemDropdown(e, record),
                    }}
                    trigger={["click"]}
                  >
                    <Button
                      className="text-lightBlue2 shadow-xl hover:text-lightBlue hover:shadow-none my-2"
                      icon={<EllipsisOutlined />}
                      shape="circle"
                      size="small"
                    />
                  </Dropdown>
                ) : null}
              </div>
            );
          }
          if (isOwner) {
            return (
              <Button
                className="text-lightBlue2 shadow-xl hover:text-lightBlue hover:shadow-none my-2"
                icon={<PlusOutlined />}
                onClick={() => onAssociatedIndicator(record)}
                shape="circle"
                size="small"
              />
            );
          }
        },
        className: "w-auto",
      },

      {
        title: "Editar",
        key: "acciones",
        align: "center",
        ellipsis: true,
        render: (text, record) => (
          <div className="flex space-x-2 justify-center">
            <Button
              className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none"
              icon={!loading.description ? <EditOutlined /> : <Spin />}
              onClick={() => onEditDescColumn(record)}
            />
          </div>
        ),
        className: "",
      },
    ];
    // eslint-disable-next-line no-debugger
    const listAux = isOwner
      ? ListColumnsDescription
      : ListColumnsDescription.filter((item) => item.key !== "acciones");

    setListColumns(listAux);

    setData(dataDescription);
    activeSection("description");
  };

  useEffect(() => {
    if (getDescriptionDoc.data) {

      const auxData = getDescriptionDoc.data;
      setIsOwner(auxData.isOwner);
      if (auxData.multipleSheets) {
        if (auxData.urlPages.length > 0) {
          const aux = transformToSelectArray(auxData.urlPages, "name", "_id", false);
          setListSheet(aux);
          const path = ROUTES.GENERAL.DOCUMENT_INFO.replace(":key", auxData.urlPages[0]._id);
          window.location.assign(path);
        } else {
          setMessage({
            type: "info",
            title: "Sin hojas",
            description: "El documento se está procesando, por favor intente más tarde.",
          });

        }

      } else {

        if (activeButton === "information") {
          if (auxData.fileExtension === "web") {
            setWebSourceActive(true);
            setIsNoFile(true);
            setUrlView(auxData.urlWebSource);
          }

          if (auxData.fileExtension === ".pdf") {
            setWebSourceActive(false);
            setIsNoFile(true);
            setPdfSourceActive(true);
            setUrlView(auxData.urlPdf);
          }

          setInfoDoc({
            headers:
              auxData.headerSize.length > 0
                ? `Fila ${auxData.headerSize[0]} a Fila ${auxData.headerSize[1]}`
                : "Ninguna",
            dataSize: auxData.dataSize.length > 0
              ? `Fila ${auxData.dataSize[0]} a Fila ${auxData.dataSize[1]}`
              : "Ninguna",
            columnMunicipality: auxData.locationData.municipalityColumn
              ? auxData.locationData.municipalityColumn
              : "Ninguna",
            columnDate: auxData.locationData.dateColumn
              ? auxData.locationData.dateColumn
              : "Ninguna",
            municipalities:
              auxData.locationData.municipality.length > 0
                ? auxData.locationData.municipality.join(", ")
                : "Ninguno",
            dateRange: auxData.locationData.dateRange
              ? auxData.locationData.dateRange
              : "Ninguna",
            keyWords:
              auxData.keyWords.length > 0
                ? auxData.keyWords.join(", ")
                : "Ninguna",
          });
          setIsFavorite(auxData.isFavorite);
          setLoading((prev) => ({ ...prev, infoDoc: false }));
        }
        setDataModalDescription(auxData);
        if (isObject(auxData.metadata)) {
          if (
            Array.isArray(auxData.metadata.columns) &&
            auxData.metadata.columns.length > 0
          ) {
            // const auxCol = auxData.metadata.columns;

            const auxCol = auxData.metadata.columns
              .filter((item) => item.name !== "UUID")
              .map((item) => ({
                ...item,
                key: item.name,
              }));

            const aux = transformToSelectArray(auxCol, "name", "name");
            setColumnsSelect(aux);
            setDataDescription(auxCol);

            if (activeButton === "information") {
              if (auxCol.length > 0 && auxCol[0].name) {
                const auxC = processColumns(auxData.metadata.columns);
                // const auxColumns = auxCol
                //   .filter((item) => item.name !== "UUID")
                //   .map((item, index) => ({
                //     // title: () => renderDropdown(item.name),
                //     id: index,
                //     title: item.name,
                //     dataIndex: item.name,
                //     key: item.name,
                //     align: "center",
                //   }));

                setListColumns(auxC);

                setDataDoc((prev) => ({ ...prev, columns: auxC }));
              } else {
                setListColumns([]);
              }
            } else if (activeButton === "description") {
              setData(auxCol);
            }
          }
        }
        if (
          auxData.fileExtension === ".xlsx" ||
          auxData.fileExtension === ".csv" ||
          auxData.fileExtension === ".xls"
        ) {
          reloadData();
        }

        getDescriptionDoc.setData();
        setLoading((prev) => ({ ...prev, infoDoc: false }));
      }
    }

    if (getDataDoc.data) {
      const auxData = getDataDoc.data;

      if (auxData.data) {
        if (Array.isArray(auxData.data) && auxData.data.length > 0) {
          setData(auxData.data);
          setDataDoc((prev) => ({ ...prev, data: auxData.data }));
        }
        if (isObject(dataModalDescription.metadata)) {
          if (Array.isArray(dataModalDescription.metadata.columns)) {
            if (dataModalDescription.metadata.columns.length === 0) {
              let auxColumns = transformColumns(auxData.data);
              auxColumns = [
                {
                  title: " ",
                  key: "index",
                  align: "center",
                  render: (text, record, index) => (
                    <div className="flex space-x-2 justify-center text-lightBlue2">
                      {index + 1}
                    </div>
                  ),
                  className: "",
                },
                ...auxColumns];
              setListColumns(auxColumns);
              setDataDoc((prev) => ({ ...prev, columns: auxColumns }));

            }
          }
        }
        setMetaData(auxData.meta);
        setLoading((prev) => ({ ...prev, table: false }));
        getDataDoc.setData();
      }
    }
    if (getParentDoc.data) {
      const auxData = getParentDoc.data;
      if (auxData.urlPages.length > 0) {

        const aux = transformToSelectArray(auxData.urlPages, "name", "_id", false);

        setListSheet(aux);
      }
      getParentDoc.setData();
    }

    if (updateDescriptionColum.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La descripción de la columna se guardo exitosamente.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      closeModal();
      // TODO: call a func to reload data table desc
      if (!clickedDropdown) {
        activeSection("description");
        getInfoDoc();
      }
      updateDescriptionColum.setData();
    }
    if (updateColumSensitive.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La columna fue actualizada correctamente.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      updateColumSensitive.setData();
      getInfoDoc();
    }
    if (getMunicipalityForRegion.data) {
      const aux = transformToTreeSelectRegions(
        getMunicipalityForRegion.data,
        "subRegion",
        "subRegion"
      );
      setListMunicipalities(aux);
      getMunicipalityForRegion.setData();
    }
    if (updateDescriptionDoc.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description:
          "La descripción de la hoja se ha actualizado correctamente",
      });
      setShowModal(false);
      setTimeout(() => {
        getInfoDoc();
        setLoading((prev) => ({ ...prev, description: false }));
      }, 5000);

      updateDescriptionDoc.setData();
    }
    if (getIndicators.data) {
      const aux = transformToTree(getIndicators.data);
      const sortedIndicator = [...aux].sort((a, b) =>
        a.title?.localeCompare(b.title)
      );
      setListGroupIndicator(sortedIndicator);
      getIndicators.setData();
    }

    if (createPostulation.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La postulación se ha realizado correctamente",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      if (!clickedDropdown) {
        activeSection("description");
        getInfoDoc();
      }
      createPostulation.setData();
    }
    if (deletePostulation.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Las postulaciones se han eliminado correctamente",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      if (!clickedDropdown) {
        activeSection("description");
        getInfoDoc();
      }
      deletePostulation.setData();
    }
    if (downloadDocument.data) {
      downloadFile(downloadDocument.data, infoDoc.title, ".csv");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El archivo fue descargado exitosamente.",
      });

      downloadDocument.setData();
    }
    if (downloadPdf.data) {
      downloadFile(downloadPdf.data, infoDoc.title, ".pdf");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El archivo fue descargado exitosamente.",
      });

      downloadPdf.setData();
    }
    if (isFavoriteDocument.data) {
      if (!isFavorite) {
        setMessage({
          type: "success",
          title: "Resultado",
          description: "El documento fue agregado a favoritos.",
        });
        setIsFavorite(true);
      } else {
        setMessage({
          type: "success",
          title: "Resultado",
          description: "El documento fue eliminado de favoritos.",
        });
        setIsFavorite(false);
      }
      isFavoriteDocument.setData();
    }
  }, [
    getDataDoc.data,
    getDescriptionDoc.data,
    getParentDoc.data,
    updateDescriptionColum.data,
    updateColumSensitive.data,
    getMunicipalityForRegion.data,
    updateDescriptionDoc.data,
    getIndicators.data,
    createPostulation.data,
    downloadDocument.data,
    downloadPdf.data,
    isFavoriteDocument.data,
    deletePostulation.data,
  ]);
  // HANDLE ERRORS
  useEffect(() => {
    if (updateDescriptionColum.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Revise los datos e inténtelo de nuevo.",
        code: updateDescriptionColum.error,
      });
      setLoading((prev) => ({ ...prev, description: false }));
    }
    if (updateColumSensitive.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
    }
    if (updateDescriptionDoc.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
    }
    if (downloadDocument.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar descargar el archivo. Inténtelo de nuevo mas tarde.",
      });
    }
    if (downloadPdf.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar descargar el archivo. Inténtelo de nuevo mas tarde.",
      });
    }
    if (isFavoriteDocument.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Revise los datos e inténtelo de nuevo.",
      });
    }
    if (createPostulation.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar realizar la postulación. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      if (!clickedDropdown) {
        activeSection("description");
      }
      createPostulation.setData();
    }
    if (deletePostulation.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar eliminar la postulación. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      if (!clickedDropdown) {
        activeSection("description");
      }
      deletePostulation.setData();
    }
  }, [
    updateDescriptionColum.error,
    updateDescriptionDoc.error,
    updateColumSensitive.error,
    downloadDocument.error,
    downloadPdf.error,
    isFavoriteDocument.error,
    createPostulation.error,
    deletePostulation.error,
  ]);

  const handlePageChange = (actualPage) => {
    setMetaData((prev) => ({ ...prev, actualPage }));
  };

  const optionsFilter = [
    {
      id: 1,
      value: "La celda está vacía",
      label: "La celda está vacía",
    },
    {
      id: 2,
      value: "La celda no está vacía",
      label: "La celda no está vacía",
    },
    {
      id: 3,
      value: "El texto contiene",
      label: "El texto contiene",
    },
    {
      id: 4,
      value: "El texto no contiene",
      label: "El texto no contiene",
    },
    {
      id: 5,
      value: "El texto comienza por",
      label: "El texto comienza por",
    },
    {
      id: 6,
      value: "El texto termina en",
      label: "El texto termina en",
    },
    {
      id: 7,
      value: "El texto es exactamente",
      label: "El texto es exactamente",
    },
    {
      id: 8,
      value: "La fecha es",
      label: "La fecha es",
    },
    {
      id: 9,
      value: "La fecha es anterior",
      label: "La fecha es anterior",
    },
    {
      id: 10,
      value: "La fecha es posterior",
      label: "La fecha es posterior",
    },
    {
      id: 11,
      value: "Mayor que",
      label: "Mayor que",
    },
    {
      id: 12,
      value: "Mayor o igual que",
      label: "Mayor o igual que",
    },
    {
      id: 13,
      value: "Menor que",
      label: "Menor que",
    },
    {
      id: 14,
      value: "Menor o igual que",
      label: "Menor o igual que",
    },
    {
      id: 15,
      value: "Es igual a",
      label: "Es igual a",
    },
    {
      id: 16,
      value: "No es igual a",
      label: "No es igual a",
    },
    {
      id: 17,
      value: "Está entre",
      label: "Está entre",
    },
    {
      id: 18,
      value: "No está entre",
      label: "No está entre",
    },
  ];

  const listDataTypes = [
    {
      id: 1,
      value: "texto",
      label: "Texto",
    },
    {
      id: 2,
      value: "numero",
      label: "Numérico",
    },
    {
      id: 3,
      value: "fecha",
      label: "Fecha",
    },
  ];

  const handleDelete = () => {
    if (activeButton === "filter") {
      // eslint-disable-next-line no-console
      console.log("delete active filter");
    } else {
      // eslint-disable-next-line no-console
      console.log("delete description");
    }
  };

  const handleChangeTab = (keyTab) => {
    setListColumns(dataDoc.columns);
    setData(dataDoc.data);
    activeSection(keyTab);
  };


  const onHandleEdit = () => {
    setShowModal(true);
  };

  const handleReload = () => {
    setLoading((prev) => ({ ...prev, reloadTable: true }));
    getInfoDoc();
    // Simular una carga de 2 segundos
    setTimeout(() => {
      setLoading((prev) => ({ ...prev, reloadTable: false }));
    }, 2000);
  };
  const onChangeSheet = (value) => {
    const path = ROUTES.GENERAL.DOCUMENT_INFO.replace(":key", value);
    window.location.assign(path);
  };

  const renderSection = () => {
    if (activeButton === "information") {
      return !loading.infoDoc ? (
        <DataPreviewInformation
          data={infoDoc}
          onHandleEdit={onHandleEdit}
          onHandleDownload={onDownloadDocument}
          handlerFavorite={onFavoriteDocument}
          isFavorite={isFavorite}
        />
      ) : (
        <div className="flex w-full justify-center">
          <Spin />
        </div>
      );
    }
    if (activeButton === "filter") {
      return (
        <DataPreviewFilter
          listActiveFilter={listActiveFilter}
          optionsFilter={optionsFilter}
          originColumn=""
          handleDelete={() => setOpenModalDelete(true)}
          listColumns={listColumns}
          actionFilterView={actionFilterView}
          setActionFilterView={() => setActionFilterView("add")}
        />
      );
    }
    if (activeButton === "profiling") {
      return <DataProfiling />;
    }
    return null;
  };

  const renderContentView = () => {
    if (!loading.table && !loading.infoDoc) {
      if (webSourceActive) {
        return (
          <IframeView
            urlView={urlView}
            className="!max-w-[calc(100vw-40px)] md:!max-w-[calc(100vw-200px)]"
          />
        );
      }
      if (pdfSourceActive) {
        return <PdfViewer pdfUrl={urlView} />;
      }
      return (
        <div className="w-full">
          <div className="w-full flex justify-end pb-4">
            <Button
              className="bg-lightBlue2 shadow-xl text-white_custom hover:text-lightBlue hover:shadow-none hover:bg-white_custom hover:border-lightBlue2"
              icon={<ReloadOutlined spin={loading.reloadTable} />}
              onClick={handleReload}
              iconPosition="end"
            >
              Actualizar tabla
            </Button>
          </div>
          <TableTemplate
            enableScroll
            data={data}
            handlePageChange={handlePageChange}
            total={metadata.totalRecords}
            currentPage={metadata.actualPage}
            columns={listColumns}
            rowKey={activeButton !== "description" ? "UUID" : "name"}
            className="!max-w-[calc(100vw-40px)] md:!max-w-[calc(100vw-200px)] "
            onlyView
            breakWords={activeButton === "description"}
            hasPagination={activeButton === "information"}
          />
        </div>
      );
    }

    return (
      <div className=" flex justify-center items-center">
        <Spin />
      </div>
    );
  };


  const renderContentModal = () => {
    if (activeButton === "information" && !clickedDropdown) {
      return (
        <SheetFormTemplate
          listColumns={columnsSelect}
          listMunicipalities={listMunicipalities}
          listCodification={listCodification}
          dataDescription={dataModalDescription}
          handlerSubmit={handlerSubmitDescription}
          loading={loading.description}
        />
      );
    }
    if (activeButton === "description" && modalIndicator) {
      return (
        <AssociateIndicatorForm
          listGroupIndicator={listGroupIndicator}
          columnName={columnNameSelected}
          fileId={params?.key}
          onHandleSubmit={handleAssociatedIndicator}
          closeModal={closeModal}
          initialValues={columnEditPostulation}
        />
      );
    }
    return (
      <ColumnTemplate
        onHandleSubmit={onHandleSaveDesc}
        dataDesColumn={dataDesColumn}
        listTypeData={listDataTypes}
        closeModal={closeModal}
        loading={loading.description}
      />
    );
  };

  return (
    <div className="flex flex-col items-center p-10 bg-white_custom">
      <div className="self-start text-base space-x-5 mb-5">

        {dataModalDescription && dataModalDescription.isChild ? (
          <SelectSheet
            label="Seleccione una hoja del documento"
            options={listSheet}
            defaultValue={dataModalDescription.id || dataModalDescription._id}
            onChange={onChangeSheet}
          />
        ) : null}
        <ValidatePermissions
          func="removeLastTab"
          container={false}
          permission="create"
          modulePermission="document_management"
        >
          <button
            onClick={() => handleChangeTab("information")}
            className={
              activeButton === "information" ? "button-underline" : undefined
            }
            type="button"
          >
            Datos
          </button>
          <button
            onClick={() => handleChangeTab("filter")}
            className={
              activeButton === "filter" ? "button-underline" : undefined
            }
            type="button"
            disabled
          >
            <Text disabled>Filtros </Text>
          </button>
          {!isNoFile ? (
            <button
              onClick={() => handleChangeTab("profiling")}
              className={
                activeButton === "profiling" ? "button-underline" : undefined
              }
              type="button"
            >
              Perfil de datos
            </button>
          ) : null}
          <button
            onClick={() => handleDescriptionClicked()}
            className={
              activeButton === "description" ? "button-underline" : undefined
            }
            type="button"
            disabled={isNoFile ? true : undefined}
          >
            {isNoFile ? <Text disabled> Columnas </Text> : "Columnas"}
          </button>
        </ValidatePermissions>
      </div>
      {activeButton !== "description" ? (
        <div className="mb-10 border-b border-[#bdb9b9]  w-full   py-5">
          {renderSection()}
        </div>
      ) : null}
      {activeButton !== "profiling" ? (
        <div className="w-full">{renderContentView()}</div>
      ) : null}

      <ModalConfirm
        title="Eliminar"
        text={`¿Está seguro de eliminar ${activeButton === "filter" ? "el filtro" : "la descripción"}?`}
        modalActive={openModalDelete}
        closeModal={() => setOpenModalDelete(false)}
        handleOK={handleDelete}
        isModalDelete
      />
      {RenderModalDeletePostulations()}
      {showModal ? (
        <AppModal
          open={showModal}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 font-bold  text-center !important"
          width={700}
        >
          {renderContentModal()}
        </AppModal>
      ) : null}
    </div>
  );
};

export default DataPreviewTemplate;
