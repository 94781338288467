import { useState } from "react";
import {
  /* DownOutlined, */ RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import CardItems from "../../atoms/Card/CardItems";
import colors from "../../../utils/ColorsPage.json";

const RenderCards = ({
  list,
  loading,
  handlerClickIndicator,
  handlerClickGroup,
}) => {
  const [isExpanded, setIsExpanded] = useState({});

  const handlerExpand = (item) => {
    const name = item?.name;
    handlerClickGroup(!isExpanded[name]);
    setIsExpanded((prev) => ({ ...prev, [name]: !isExpanded[name] }));
  };

  const handlerClick = (item) => {
    if (Array.isArray(item.child_groups) && item.child_groups.length > 0) {
      handlerExpand(item);
    } else {
      handlerClickIndicator(item);
    }
  };

  const renderButtons = (item) => {
    const name = item?.name;
    const isLastChild = item.child_groups.length > 0 ? "parent" : "last"
    const showIconExpand = () => {
      if (isLastChild === "parent") {
        return isExpanded[name] ? <UpOutlined /> : <RightOutlined />;
      }
      return null;
    };
    return (
      <>
        <button
          className={`flex items-center hover:bg-[#D3D5D9] h-auto min-h-7 pl-5 level-tree-${item.level} tree-${isLastChild}`}
          onClick={() => handlerClick(item)}
          type="button"
          key={name}
        >
          <div className="flex self-center">{showIconExpand()}</div>
          <p className="text-left ml-2">{name}</p>
        </button>
        {isExpanded[name] ? (
          <div className={`flex flex-col parent-level-tree-${item.level}`}>
            {Array.isArray(item.child_groups) &&
              item.child_groups.map((ind) => renderButtons(ind, ind?.level))}
          </div>
        ) : null}
      </>
    );
  };

  const renderCards = () => {
    if (!loading) {
      return list.map((item, i) => (
        <CardItems key={item?.name} className="flex">
          <div
            className="mr-4 rounded-full h-auto"
            style={{
              backgroundColor: colors[i],
              minHeight: "50px",
              width: "3px",
            }}
          />
          <div className="flex flex-col justify-center w-full h-auto min-h-[70px]">
            {renderButtons(item)}
          </div>
        </CardItems>
      ));
    }
    return <Spin />;
  };

  return renderCards();
};

export default RenderCards;
