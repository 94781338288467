import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import AppImg from "../../components/atoms/Img/AppImg";
import logoAune from "../../assets/image/landingpage/aune-white.png";
import pacifico from "../../assets/image/landingpage/pacifico.png";
import mapaCol from "../../assets/image/landingpage/mapCop.png"
import user from "../../assets/image/landingpage/iconGov.png";
import lupa from "../../assets/image/landingpage/investigadores.png";
import plataforma from "../../assets/image/landingpage/plataforma.png";
import AppText from "../../components/atoms/Text/AppText";

export default function LandingPage() {
  const navigate = useNavigate();

  const onEnterPlatform = () => {
    navigate("/login");
  };

  return (
    <section className="bg-black relative h-screen flex flex-col items-center text-white">
      {/* Background Image */}
      <div className="flex flex-col w-full p-4">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${pacifico})` }}
        />
        {/* Main Content */}
        <div className="w-full relative z-10 flex-col items-left mt-10 p-4">
          <AppImg src={logoAune} alt="Logo" className="w-2/4" />
          <div className="w-full flex flex-col items-left ">
            <AppText
              title="Lorem ipsum dolor sit amet, consectetuer"
              type="secondary"
              className="text-2xl text-white font-bold"
            />
            <AppText
              title="adipiscing elit. Aenean commodo"
              type="secondary"
              className="text-xl text-white  "
            />
            <AppText
              title="ligula eget dolor. Aenean massa."
              type="secondary"
              className="text-xl text-white  "
            />
          </div>

          <Button
            onClick={onEnterPlatform}
            className="flex bg-[#4CACBC] shadow-lg h-[35px] rounded-6 min-w-28 text-white font-normal text-lg text-center mt-4  hover:bg-[#3B9AA8] hover:shadow-xl border-none"
          >
            Ingresar a la plataforma
          </Button>
        </div>
        {/* Platform Description */}
        <div className="w-full flex justify-center">
          <div className="relative z-10 rounded-lg w-3/4 bg-[#000] flex flex-col bg-opacity-70 p-4 items-center text-center ">
            <AppText
              title="La plataforma AUNE"
              type="secondary"
              className="text-white max-w-2xl mx-auto"
            />
            <AppText
              title="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
              type="secondary"
              className="text-white max-w-2xl mx-auto"
            />
          </div>
        </div>
      </div>

      {/* Context Section */}
      <div className="bg-[#838686] relative z-10 w-full p-8 text-center">
        <h2 className="text-3xl font-bold text-[#4CACBC] mb-6">Contexto</h2>
        <div className="w-full flex flex-col items-center">
          <p className="w-2/4 text-white max-w-2xl mx-auto mb-4">
            El proyecto parte de la hipótesis de que la violencia está teniendo consecuencias negativas y cada vez más graves en la salud de la población del pacífico nariñense y esto porque <strong>con la violencia se está negando el derecho a la salud</strong>, lo que lleva a la afectación de la salud física y mental de las poblaciones.
          </p>
          <div className="w-scren flex flex-row">
            <div>
              <p className="text-white max-w-2xl mx-auto mb-4">
                Las consecuencias se distribuyen de manera desigual sobre los distintos sectores de población y en las diferentes subregiones de la costa pacífica nariñense.
              </p>
              <p className="text-white max-w-2xl mx-auto mb-4">
                En función de la intensidad y de las diferentes modalidades del conflicto.
              </p>
            </div>
            <div className="my-4">
              {/* Add context image */}
              <AppImg src={mapaCol} alt="Context Image" className="w-full" />
            </div>
            <div>
              <p className="w-4/5 bg-[#F7C04A] rounded-lg text-[#000] max-w-2xl mx-auto mb-6 p-3">
                <strong>El bienestar de la región pacífica</strong> requiere que el Estado proteja los derechos humanos y para ello requiere contar con herramientas que permitan analizar las causas estructurales e intermedias que determinan la salud y sus efectos por cuenta de la violencia.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* User Roles Section */}
      <div className="relative z-10 w-full p-8 text-center bg-gray-900 bg-opacity-70">
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
          <div className="flex-1 flex flex-col items-center bg-white rounded-lg p-6 shadow-lg">
            <div className="relative -top-10 w-18 h-18 rounded-full overflow-hidden border-4 border-white shadow-md">
              <AppImg src={user} alt="Funcionario Image" className="w-full h-full bg-[#fff] object-cover" />
            </div>
            <div className="pt-auto flex flex-col flex-1 justify-between">
              <div>
                <h3 className="text-xl font-bold mb-2 text-[#4CACBC]">Como funcionario de gobierno</h3>
                <p className="text-gray-700 mb-4 text-[#4CACBC]">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula.
                </p>
              </div>
              <Button className="bg-[#4CACBC] text-white font-normal text-lg px-8 py-2 rounded-full hover:bg-teal-600">
                Ir a la plataforma
              </Button>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center bg-white rounded-lg p-6 shadow-lg">
            <div className="relative -top-10 w-18 h-18 rounded-full overflow-hidden border-4 border-white shadow-md">
              <AppImg src={lupa} alt="Investigador Image" className="w-full h-full bg-[#fff] object-cover" />
            </div>
            <div className="pt-auto flex flex-col flex-1 justify-between">
              <div>
                <h3 className="text-xl font-bold mb-2 text-[#4CACBC]">Como investigador</h3>
                <p className="text-gray-700 mb-4 text-[#4CACBC]">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula.
                </p>
              </div>
              <Button className="bg-[#4CACBC] text-white font-normal text-lg px-8 py-2 rounded-full hover:bg-teal-600">
                Ir a la plataforma
              </Button>
            </div>
          </div>
        </div>
      </div>


      {/* Platform Section */}
      <div className="relative z-10 w-full p-8 text-center bg-[#EBEBEB]">
        <h2 className="text-3xl  text-[#4CACBC] font-bold mb-6">La plataforma AUNE</h2>
        <p className="text-[#000] max-w-2xl mx-auto mb-4">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
        </p>
        <div className="flex justify-center mb-6">
          <div className="w-full max-w-xl">
            {/* Add platform image */}
            <AppImg src={plataforma} alt="Platform Image" className=" top-0 left-0 w-full h-full bg-cover bg-center" />
          </div>
        </div>
        <Button
          className="bg-[#6DBFA4] bg-teal-500 text-white font-normal text-lg px-8 py-2 rounded-full hover:bg-teal-600"
        >
          Crear cuenta
        </Button>
      </div>
    </section>
  );
}
