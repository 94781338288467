import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
import IndicatorFormTemplate from "../../components/templates/IndicatorTemplate/IndicatorFormTemplate";

const { Content } = Layout;
const responseData = {
  variable1: "1",
  variable2: "2",
  variable3: "3",
  variable4: "4",
  denominator: "Población Total",
  numerator: "Población Alfabetizada",
  name: "Indicador Educativo",
  type: "1",
};

const listVariable = [
  {
    value: "1",
    label: "Población Alfabetizada",
  },
  {
    value: "2",
    label: "Población Total",
  },
  {
    value: "3",
    label: "población en edad escolar",
  },
  {
    value: "4",
    label: "población analfabeta",
  },
];
// tipos de indicadores trazadores
const types = [
  {
    value: "1",
    label: "Indicador Educativo",
  },
  {
    value: "2",
    label: "Indicador de Salud",
  },
  {
    value: "3",
    label: "Indicador de Seguridad",
  },
];

const IndicatorRegister = ({ isEdit = false }) => {
  const { id } = useParams(); // Extracción correcta del key
  // const [idCol, setIdCol] = useState(null);
  const [dataInitial] = useState(responseData);
  const [listType] = useState(types);

  useEffect(() => {
    if (isEdit) {
      // llamar a la api para editar
      // setDataInitial(responseData);
    } else {
      // llamar a la api para registrar
      // setListColumns(dataCol);
      // setListTypeData(dataTypes);
    }
  }, [isEdit, id, dataInitial]);
  return (
    <Layout>
      <Content>
        <IndicatorFormTemplate
          listType={listType}
          listVariable={listVariable}
          isEdit={isEdit}
          dataInitial={dataInitial}
        />
      </Content>
    </Layout>
  );
};

export default IndicatorRegister;
