/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Form } from "antd";
// import "../../../assets/css/styleSelect.css";
import FormPassword from "../../molecules/FormInput/FormPassword";
import AppTitle from "../../atoms/Title/AppTitle";
import AppTex from "../../atoms/Text/AppText";
import FormInput from "../../molecules/FormInput/FormInput";
import SubmitButton from "../../atoms/Buttons/SubmitButton";

const ChangePassworForm = ({ handlerPassword, loading }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      className="font-Poppins"
      onFinish={handlerPassword}
    >
      <div className="  flex justify-center items-center mb-2">
        <div className="  p-6 ">
          <div className=" mb-2 grid justify-items-center ">
            <AppTitle
              title="Cambiar de contraseña"
              level={2}
              className="font-bold text-lightBlue mb-6"
            />
          </div>
          <div className="grid justify-center items-center mb-2">
            <FormPassword
              name="password"
              label="Nueva contraseña:"
              hasFeedback
              labelCol={{ span: 24 }}
            />
            <FormPassword
              name="confirm"
              label="Confirmación de contraseña:"
              labelCol={{ span: 24 }}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Por favor confirme la contraseña!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("¡Las contraseñas que ingresó no coinciden!")
                    );
                  },
                }),
              ]}
            />
            <AppTex
              className="font-Poppins font-light text-textTable text-color_text text-justify"
              title="Un código ha sido enviado al correo electrónico para autorizar este cambio, por favor introduzca el código :"
            />

            <FormInput
              name="code"
              // label="Código de verificación:"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el código",
                  whitespace: true,
                },
                {
                  pattern: /^([0-9]{6}$)/,
                  message:
                    "El código debe tener exactamente 6 dígitos y contener solo números del 0 al 9 ",
                },
              ]}
            />
            <Form.Item>
              <div className="flex justify-center items-center">
                <SubmitButton
                  className="font-normal text-center mt-10"
                  nameButton="Cambiar contraseña"
                  htmlType="submit"
                  form={form}
                  loading={loading}
                />
              </div>
            </Form.Item>
          </div>

          <div className="flex justify-center items-center">
            <AppTex
              className="font-Poppins font-light text-sm text-color_text"
              title="¿No recibió el código?"
            />
          </div>
          <div className="flex justify-center items-center">
            <a
              href="#"
              className="font-light text-sm text-center text-lightBlue  hover:text-blue-700"
            >
              Enviar de nuevo
            </a>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ChangePassworForm;
