/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { createContext, useContext, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";
import useApi from "../api/useApi";
import auth from "../api/auth";
import token from "../api/token";

const AuthContext = createContext();
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [message, setMessage] = useState("");
  const [permissions, setPermissions] = useState([]);
  const loginUser = auth.login;
  const logoutUser = auth.logout;
  const loginRequest = useApi(loginUser);
  const logoutRequest = useApi(logoutUser);

  /*   function register(email, password) {
    return createUser(auth, email, password);
  } */

  function login(email, password) {
    return loginRequest.request({ email, password });
  }

  function logout() {
    return logoutRequest.request();
  }

  useEffect(() => {
    if (logoutRequest.data) {
      token.removeToken();
      token.removeRefreshToken();
      setCurrentUser();
      logoutRequest.setData();
    }
  }, [logoutRequest.data]);

  useEffect(() => {
    if (logoutRequest.error) {
      token.removeRefreshToken();
      token.removeToken();
      setCurrentUser();
    }
  }, [logoutRequest.error]);

  function refreshDataUser(auxToken) {
    let accessToken;
    if (auxToken) {
      accessToken = auxToken;
    } else {
      accessToken = token.getToken();
    }
    if (accessToken) {
      const tokenDecode = jwtDecode(accessToken);
      const { sub, email, firstName, lastName, role } = tokenDecode;
      setCurrentUser({ sub ,email, firstName, lastName, role });
    }
  }

  /*   function updateUserProfile(user, profile) {
    return updateProfile(user, profile);
  } */

  /*   useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []); */

  const value = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      login,
      logout,
      refreshDataUser,
      message,
      setMessage,
      permissions,
      // register,
      setPermissions,
      // updateUserProfile,
    }),
    [
      currentUser,
      setCurrentUser,
      // register,
      login,
      logout,
      refreshDataUser,
      message,
      setMessage,
      permissions,
      setPermissions,
      // updateUserProfile,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
