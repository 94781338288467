/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import user from "../../api/user";
import useApi from "../../api/useApi";
import UserRegisterTemplate from "../../components/templates/UserTemplate/RegisterTemplate";
import { useAuth } from "../../contexts/AuthContext";


// import "../../assets/css/styleSelect.css";

const roles = [
  {
    id: 1,
    value: "Funcionario de Gobierno",
    label: "Funcionario Público ",
  },
  {
    id: 2,
    value: "Usuario General",
    label: "Usuario General",
  },
];
const optionsGender = [
  {
    id: 1,
    value: "F",
    label: "Femenino ",
  },
  {
    id: 2,
    value: "M",
    label: "Masculino",
  },
  {
    id: 3,
    value: "NoInfo",
    label: "Prefiero no decirlo ",
  },
];
const UserCreatePage = () => {
  const createRequest = useApi(user.createUser);
  const uploadPdf = useApi(user.uploadPdf);
  const [files, setFiles] = useState([]);
  const { setMessage } = useAuth();
  const navigate = useNavigate();
  // const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  // const onUpload = (userData, image) => {
  //   addRequest.request(userData, image);
  // };
  const sanitizeFileName = (originalName) => {
    // Reemplaza guiones medios con guiones bajos y elimina otros caracteres no deseados
    return originalName
      .replace(/-/g, "_")
      .replace(/[^a-zA-Z0-9._\s()áéíóúüñÁÉÍÓÚÜÑ]/g, "");
  };

  useEffect(() => {
    if (createRequest.data) {
      const auxData = createRequest.data.user;
      const id = auxData.id || auxData._id;
      if (files) {
        const [dni, entityCard] = files;
        if (dni) {
          const { name, size } = dni;
          const fileName = sanitizeFileName(name);
          const fileExtension = `.${name.split(".").pop()}`;
          const formData = new FormData();
          formData.append("pdf", dni.originFileObj, fileName);
          uploadPdf.request(
            id,
            formData,
            fileName,
            fileExtension,
            size,
            "DNI");
        }
        if (entityCard) {
          const { name, size } = entityCard;
          const fileName = sanitizeFileName(name);
          const fileExtension = `.${name.split(".").pop()}`;
          const formData = new FormData();
          formData.append("pdf", entityCard.originFileObj, fileName);
          uploadPdf.request(
            id,
            formData,
            fileName,
            fileExtension,
            size,
            "ENTITY_CARD"
          );
        }

        setMessage({
          type: "info",
          title: "Registro pendiente",
          description: "Usuario creado correctamente. Se le enviará un correo en cuanto se apruebe el registro.",
        });
        setLoading(false);
      } else {
        setMessage({
          type: "success",
          title: "Usuario creado",
          description: "Usuario creado correctamente.",
        });
      }
      setLoading(false);
      navigate("/");
    }

  }, [createRequest.data]);

  useEffect(() => {
    if (createRequest.error) {
      
      if (createRequest.error === "User already exists") {
        setMessage({
          type: "error",
          title: "Error",
          description: "El usuario con ese correo ya se encuentra registrado en el sistema. Por favor, inicie sesión.",
        });
      } else {
        setLoading(false);
        setMessage({
          type: "error",
          title: "Error",
          description: "Error al crear el usuario en el sistema. Por favor, intente nuevamente.",

        });
      }
      setLoading(false);
    }
  }
  , [createRequest.error]);


  const handleSave = (values) => {
    setLoading(true);
    const { phone, dni, gender, entityCard, ...rest } = values;
    let auxGender;
    switch (gender) {
      case "F":
        auxGender = "Femenino";
        break;
      case "M":
        auxGender = "Masculino";
        break;
      default:
        auxGender = "NoInfo";
    }
    const userData = {
      ...rest,
      gender: auxGender,
      phone: phone.toString()
    };
    createRequest.request(userData, files);
    setFiles([dni, entityCard]);
  };


  return (

    <UserRegisterTemplate
      saveData={handleSave}
      loading={loading}
      roles={roles}
      optionsGender={optionsGender}
    />

  )
};

export default UserCreatePage;