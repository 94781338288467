import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import token from "../api/token";
import { useAuth } from "../contexts/AuthContext";
import Header from "../components/organisms/Header/Header";

const RequireAuth = ({ children }) => {
  const refreshToken = token.getRefreshToken();
  const [isAuthenticated, setIsAuthenticated] = useState(!!refreshToken);
  const accessToken = token.getToken();
  const { refreshDataUser, setCurrentUser, setMessage } = useAuth();

  const isTokenExpired = (tok) =>
    Date.now() >= JSON.parse(atob(tok.split(".")[1])).exp * 1000;

  const showExpiredMessage = () => {
    setMessage({
      type: "error",
      description:
        "Su sesión ha expirado. Si desea continuar navegando vuelva a iniciar sesión.",
    });
  };

  useEffect(() => {
    const { pathname } = window.location;
    if (!pathname.includes("login")) {
      if (refreshToken) {
        if (isTokenExpired(refreshToken)) {
          token.removeRefreshToken();
          token.removeToken();
          setCurrentUser();
        }

        if (!accessToken) {
          token.refreshingToken();
          setTimeout(() => {
            refreshDataUser();
          }, 1000);
        }
      } else {
        showExpiredMessage();
        token.removeToken();
        setCurrentUser();
        setIsAuthenticated(false);
      }
    }
  }, [accessToken, refreshToken, children]);

  return isAuthenticated ? (
    <>
      <Header />
      {children}
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default RequireAuth;
