import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const useTokenMonitor = () => {
  const getToken = () => Cookies.get("refreshToken") 
  const [token, setToken] = useState(getToken());
  const navigate = useNavigate();
  const { pathname } = window.location;
  
  useEffect(() => {
    const interval = setInterval(() => {
      const newToken = getToken();
      if (!newToken) {
        clearInterval(interval); // Clear interval to stop checking once logged out
        if(!pathname.includes("/recovery_password") && !pathname.includes("/landing_page") && !pathname.includes("/login") && !pathname.includes("/register"))
          navigate("/login"); // Redirect to login page
      }
      setToken(newToken);
    }, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return token;
};

export default useTokenMonitor;
