const transformToTreeSelect = (array, labelID, valueID, titleFather = "Nariño") => {
  if (!Array.isArray(array)) return "";
  const newArray = array.map((item) => {
    const newValue = item[valueID] || "";
    const newLabel = item[labelID] || "";

    return {
      title: newLabel,
      key: newLabel,
      value: newValue,
    };
  });
  return [
    {
      title: titleFather,
      value: "all",
      key: "all",
      children: newArray,
    },
  ];
};

export default transformToTreeSelect;
