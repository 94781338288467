import api from ".";





const getEvents=(EventType)=>{
  const url=`/files/event-types?type=${EventType}`;
  return api.get(url);
}

const getAllEvents=()=>{
  const url="/files/event-types";
  return api.get(url);
}

const getDataMap = (data)=>{
  const url="/files/events" ;
  const result = api.post(url,data);
  return result;
}
export default {
  getEvents,
  getAllEvents,
  getDataMap
};
