import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, Form } from "antd";
import FormTreeSelect from "../../molecules/FormTree/FormTreeSelect";
import useApi from "../../../api/useApi";
import document from "../../../api/document";
import user from "../../../api/user";
import transformToTreeSelect from "../../../helpers/transformToTreeSelect";
import isObject from "../../../helpers/isObject";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import FormInputSearch from "../../molecules/FormInput/FormInputSearch";
import DisplayCards from "../../atoms/DisplayCards/DisplayCards";
import { useAuth } from "../../../contexts/AuthContext";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import SubmitButtonNotValidate from "../../atoms/Buttons/SubmitButtonNotValidate";

const ShareFileTemplate = ({ infoFile, isWebSource }) => {
  const [form] = Form.useForm();
  const params = useParams();
  const { setMessage } = useAuth();
  const getInfoSharedFile = useApi(document.getInfoSharedFile);
  const updateInfoSharedFile = useApi(document.updateInfoSharedFile);
  const getAllUsers = useApi(user.getAllUsers);
  const [listTree, setListTree] = useState([]);
  const [listSharingUsers, setListSharingUsers] = useState([]);
  const [accessOption, setAccessOption] = useState("");
  const [loading, setLoading] = useState({ update: false });
  const [listUsers, setListUsers] = useState([]);

  const listSharingTypes = [
    { value: "Restringido", label: "RESTRINGIDO" },
    { value: "Investigadores", label: "INVESTIGADORES" },
    { value: "Publico General", label: "PUBLICO GENERAL" },
  ];

  useEffect(() => {
    getInfoSharedFile.request(params?.key);
  }, []);

  useEffect(() => {
    if (infoFile) {
      if (isObject(infoFile.metadata)) {
        if (
          Array.isArray(infoFile.metadata.columns) &&
          infoFile.metadata.columns.length > 0
        ) {
          const auxTree = transformToTreeSelect(
            infoFile.metadata.columns,
            "name",
            "name",
            "Todas las columnas"
          );
          setListTree(auxTree);
        }
      }
    }
  }, [infoFile]);

  useEffect(() => {
    if (getInfoSharedFile.data) {
      const auxInfo = getInfoSharedFile.data.users;
      if (auxInfo) {
        const { visibility, sharedUsers, columns } = auxInfo;
        setAccessOption(visibility);
        setListSharingUsers([]);
        let sharedColumns = [];
        if (Array.isArray(columns)) {
          const auxSharedColumns = columns.filter((item) => item.shared);
          sharedColumns = auxSharedColumns.map((item) => item.name);
        }
        if (Array.isArray(sharedUsers)) {
          let auxList = transformToSelectArray(
            sharedUsers,
            "joinValues-firstName,lastName,role",
            "id"
          );
          auxList = auxList.map((item) => ({ ...item, name: item.label }));
          setListSharingUsers(auxList);
        }
        form.setFieldsValue({ visibility, columns: sharedColumns });
      }
      getInfoSharedFile.setData();
    }
    if (updateInfoSharedFile.data) {
      setLoading((prev) => ({ ...prev, update: false }));
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Los datos de compartir se guardaron exitosamente.",
      });
      form.resetFields(["sharedWith"]);
      getInfoSharedFile.request(params?.key);
      updateInfoSharedFile.setData();
    }

    if (getAllUsers.data) {
      const auxList = getAllUsers.data;
      if (auxList.length > 0) {
        const arraySelect = transformToSelectArray(
          auxList,
          "joinValues-firstName,lastName,role",
          "id"
        );
        setListUsers(arraySelect);
      }
      getAllUsers.setData();
    }
  }, [getInfoSharedFile.data, updateInfoSharedFile.data, getAllUsers.data]);

  useEffect(() => {
    if (getInfoSharedFile.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al obtener los datos de compartir. Por favor recargue la pagina.",
        code: getInfoSharedFile.error,
      });
    }
    if (updateInfoSharedFile.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al actualizar los datos de compartir. Por favor inténtelo de nuevo mas tarde.",
        code: updateInfoSharedFile.error,
      });
      setLoading((prev) => ({ ...prev, update: false }));
    }
  }, [getInfoSharedFile.error, updateInfoSharedFile.error]);

  const onDeleteCard = (pos) => {
    setListSharingUsers((prev) => {
      const auxList = [...prev];
      auxList.splice(pos, 1);
      return auxList;
    });
  };

  const handleSubmit = (values) => {
    setLoading((prev) => ({ ...prev, update: true }));
    const auxVisibility = values.visibility;
    let data = {
      visibility: auxVisibility,
    };
    if (!isWebSource) {
      const auxColumns = listTree[0].children;
      const flatArray = auxColumns.map((item) => item.value);
      const auxSharedColumns = values.columns;
      let notSharedColumns = [];
      if (auxSharedColumns.length > 0 && auxSharedColumns[0] !== "all") {
        const missingElements = flatArray.filter(
          (item) => !auxSharedColumns.includes(item)
        );
        notSharedColumns = missingElements.map((item) => ({
          name: item,
          shared: false,
        }));
      }
      data = { ...data, columns: notSharedColumns };
    }

    if (auxVisibility === "Restringido") {
      const auxListSharingUsers = Array.isArray(listSharingUsers)
        ? listSharingUsers.map((item) => item.value)
        : [];
      const auxListUsers = values.sharedWith || [];
      const auxJoinArray = auxListSharingUsers.concat(auxListUsers);
      const uniqueArray = auxJoinArray.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      data = { ...data, sharedWithUserIds: uniqueArray };
    }
    updateInfoSharedFile.request(params?.key, data);
  };

  const handleChangeSharedWith = (value) => {
    if (value.length >= 3) getAllUsers.request(value);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className="flex flex-col items-center py-10 px-10 md:px-32 bg-white_custom"
    >
      <div className="flex justify-start w-full mb-6 mt-4">
        <strong className="font-light text-color_text mr-2">
          Nombre del archivo:
        </strong>
        <span>{infoFile?.name}</span>
      </div>
      <FormSelect
        name="visibility"
        label="Acceso general:"
        className="font-semibold text-color_text text-textTable w-full"
        selectClass="formBgWhite"
        options={listSharingTypes}
        onChange={(value) => setAccessOption(value)}
        defaultValue="Restringido"
      />
      {accessOption === "Restringido" ? (
        <DisplayCards
          list={listSharingUsers}
          onDeleteCard={onDeleteCard}
          title="Usuarios con acceso:"
        />
      ) : null}

      <Divider />
      {accessOption === "Restringido" || !isWebSource ? (
        <strong className="text-center text-darkAquamarineBlue font-bold text-lg mb-5">
          Compartir Archivo
        </strong>
      ) : null}
      {accessOption === "Restringido" ? (
        <FormInputSearch
          className="select-share"
          mode="multiple"
          name="sharedWith"
          label="Compartir con:"
          options={listUsers}
          styleForm="font-semibold text-color_text text-textTable w-full"
          placeholder="Escribe al menos 3 caracteres del nombre del usuario o el rol para empezar a buscar"
          onSearch={handleChangeSharedWith}
        />
      ) : null}
      {!isWebSource ? (
        <FormTreeSelect
          treeData={listTree}
          placeholder="Selecciona tus opciones"
          maxTagCount={4}
          name="columns"
          label="Columnas a compartir:"
          labelCol={{ span: 24 }}
          className="treeMunicipality"
          styleItem="w-full"
          defaultValue="Todas las columnas"
          treeDefaultExpandAll
          rules={[
            {
              required: true,
              message: "Este campo es requerido",
            },
          ]}
        />
      ) : null}
      <Form.Item>
        <div className="flex justify-center text-center pt-2">
          <SubmitButtonNotValidate
            nameButton="Guardar"
            loading={loading.update}
            htmlType="submit"
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default ShareFileTemplate;
