/* eslint-disable react/no-unstable-nested-components */
// import "../../../index.css";
import React from "react";
import { Form, Input, ConfigProvider } from "antd";
import { LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { inputPassLoginStyle } from "../../../assets/theme/themeConfig";


// import "../../../assets/css/stylesLogin.css"; // Asegúrate de reemplazar 'styles.css' con la ruta a tu archivo CSS
/* eslint-disable react/jsx-props-no-spreading */

const FormPassLogin = ({ className = "", ...props }) => {
  const combinedClassName = `font-Poppins text-black_custom text-sizeBase w-auto${className}`;
  return (
    <ConfigProvider
      theme={{
        components: {
          "Input": inputPassLoginStyle,
        },
      }}
    >
      <Form.Item
        tooltip="La contraseña debe contener al menos una letra minúscula, una letra mayúscula, un número y un carácter especial"
        rules={[
          {
            required: true,
            message: "Por favor ingrese una contraseña",
          },
          {
            max: 20,
            message: "Máximo 20 caracteres",
          },
          {
            min: 8,
            message: "Mínimo 8 caracteres",
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#%^&()_+{}|:;,.?/])[A-Za-z\d!@#%^&()_+{}|:;,.?/].{8,}$/,
            message:
              "La contraseña debe contener al menos una letra minúscula, una letra mayúscula, un número y un carácter especial",
          },
        ]}
        className={combinedClassName}
        {...props}
      >

        <Input.Password className="w-11/12 text-back_custom text-sizeBase font-Poppins rounded-full  !important"
          size="large"
          prefix={< LockOutlined style={{ color: "white", "fontSize": "27px", padding: "5px", alignItems: "center", }} />}
          iconRender={(visible) => (visible ? <EyeTwoTone style={{ color: "white", "fontSize": "20px" }} /> : <EyeInvisibleOutlined style={{ color: "white", "fontSize": "20px", }} />)}

        />

      </Form.Item>
    </ConfigProvider>
  )
}
export default FormPassLogin;