import React from "react";
import { ConfigProvider } from "antd";
import FormInput from "../../molecules/FormInput/FormInput";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";


// eslint-disable-next-line arrow-body-style
const VariableForm = ({ listColumns, listAgregation, isEdit }) => {



  return (
    <ConfigProvider
      theme={{
        components: {
          "Select": selectWhiteStyle,
        },
      }}
    >

      <div className="container mx-auto px-4 py-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Columna 1 - Fila 1 */}
          <div className="md:col-span-1 ">
            <FormInput
              name="name"
              label="Nombre:"
              className="text-color_text text-textTable"
              size="small"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Por favor Ingrese un nombre" }]}
            />


          </div>

          {/* Columna 2 - Fila 1 (espacio vacío) */}
          <div className="md:block md:col-span-1 ">
            <FormSelect
              name="fontColumn"
              label="Columna fuente :"
              className="font-semibold text-color_text text-textTable "
              // popupClassName="font-normal text-color_text text-textTable"
              options={listColumns} // Asegúrate de tener esta lista o ajusta según tus necesidades
              size="small"
              labelCol={{ span: 24 }}
              // spanCls="pb-2"
              disable={isEdit}
              rules={[{ required: true, message: "Por favor seleccione una columna" }]}
            />
          </div>

          {/* Columna 3 - Fila 1 */}
          <div className="md:col-span-1  ">
            <FormSelect
              name="aggregation"
              label="Agregación :"
              className="font-semibold text-color_text text-textTable "
              // popupClassName="font-normal text-color_text text-textTable"
              options={listAgregation} // Asegúrate de tener esta lista o ajusta según tus necesidades
              size="small"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Por favor seleccione una opción" }]}

            />
          </div>

        </div>
      </div>
    </ConfigProvider>


  )
};

export default VariableForm;
