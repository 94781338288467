const Config = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  urlBackend: process.env.REACT_APP_URL_BACKEND,
  // urlBackend: process.env.REACT_APP_URL_BACKEND,
  urlETL: process.env.REACT_APP_URL_ETL,
  tokenETL: process.env.REACT_APP_TOKEN_ETL,
  keyRecap: process.env.REACT_APP_KEY_RECAPTCHA_GOOGLE,
  timeOut: 36000,
};

export default Config;
