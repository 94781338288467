import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const DataPreviewInformation = ({ data, onHandleEdit }) => (
  <div className="flex flex-col  w-full">
    <p className="text-lightBlue2 font-bold text-lg mb-4">Descripción de la hoja</p>
    {/* <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0"> */}
    <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-x-20 gap-y-2 sm:gap-y-5">
      <div className="flex flex-col space-y-2">
        <p>Encabezado de la hoja: <strong className="text-colorBorder">{data.headers}</strong></p>
        <p>Filas de datos: <strong className="text-colorBorder">{data.dataSize}</strong></p>
        <p>Palabras clave: <strong className="text-colorBorder">{data.keyWords}</strong></p>
      </div>
      <div className="flex flex-col space-y-2">
        <p>Columna de municipio: <strong className="text-colorBorder">{data.columnMunicipality}</strong></p>
        <p>Municipios: <strong className="text-colorBorder">{data.municipalities}</strong></p>
      </div>
      <div className="flex flex-col space-y-2">
        <p>Columna de fecha de muestra: <strong className="text-colorBorder">{data.columnDate}</strong></p>
        <p>Fecha de muestra: <strong className="text-colorBorder">{data.dateRange}</strong></p>
      </div>

    </div>
    <div className="flex justify-end items-end pt-2">
      <Button
        className="bg-lightBlue2 shadow-xl text-white_custom hover:text-lightBlue hover:shadow-none hover:bg-white_custom hover:border-lightBlue2"
        icon={<EditOutlined />}
        onClick={() => onHandleEdit(data)}
      >
        Editar
      </Button>
    </div>
  </div>
);

export default DataPreviewInformation;
