import React, { useEffect, useState } from "react";
import { ConfigProvider, Divider } from "antd";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";
import FormSelectSearch from "../../molecules/FormSelect/FormSelectSearch";
import FormInput from "../../molecules/FormInput/FormInput";
import FormDate from "../../molecules/FormDate/FormDate";
import FormTreeSelect from "../../molecules/FormTree/FormTreeSelect";
import FormNumber from "../../molecules/FormInput/FormNumber";

const SheetEditForm = ({
  listColumns = [],
  listMunicipalities = [],
  listCodification,
  dataDescription,
  validatorCode,
  validatorHeader,
  WebSource = false,
  setInputValue,
  handleInputKeyDown,
  searchValue,
}) => {
  const [columnMunicipalitySelected, setColumnMunicipalitySelected] = useState(false);
  const [columnDateSelected, setColumnDateSelected] = useState(false);
  const [isWebSource, setIsWebSource] = useState(WebSource);

  useEffect(() => {
    if (dataDescription) {
      if (dataDescription.municipalityColumn) {
        setColumnMunicipalitySelected(true);
      }
      if (dataDescription.dateColumn) {
        setColumnDateSelected(true);
      }
    }
  }, [dataDescription]);

  useEffect(() => {
    setIsWebSource(WebSource);
  }, [WebSource]);

  const handleMunicipalityChange = (selectedValue) => {
    setColumnMunicipalitySelected(selectedValue !== "0");
  };

  const handleDateChange = (selectedValue) => {
    setColumnDateSelected(selectedValue !== "0");
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: selectWhiteStyle,
        },
      }}
    >
      <div className="flex flex-col md:flex-row mb-5">
        {!isWebSource && (
          <div className="w-full md:w-1/2">
            <div className="w-full">
              <p className="my-5 text-lg text-lightBlue2 font-bold">Nombre de la hoja</p>
              <FormInput
                name="name"
                label="Editar nombre de la hoja:"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Este campo es requerido" }]}
              />
            </div>

            <p className="my-5 text-lg text-lightBlue2 font-bold">Encabezado de la hoja</p>
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col">
                <p className="font-Poppins font-medium text-color_text text-start">Filas del encabezado</p>
                <div className="flex space-x-4">
                  <FormNumber
                    name="headerStart"
                    label="Desde:"
                    labelCol={{ span: 24 }}
                    rules={[
                      { required: true, message: "Este campo es requerido" },
                      { type: "number", min: 1, message: "El número debe ser mayor a cero" },
                      { validator: (_, value) => Number.isInteger(value) ? Promise.resolve() : Promise.reject(new Error("El número debe ser un entero")) },
                    ]}
                    minNumber={1}
                    styleInput="w-full"
                  />
                  <FormNumber
                    name="headerEnd"
                    label="Hasta:"
                    labelCol={{ span: 24 }}
                    dependencies={["headerStart"]}
                    rules={[
                      { required: true, message: "Este campo es requerido" },
                      { type: "number", min: 1, message: "El número debe ser mayor a cero" },
                      ...validatorHeader("headerStart"),
                    ]}
                    minNumber={1}
                    styleInput="w-full"
                  />
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <p className="font-Poppins font-medium text-color_text text-start">Filas de los datos</p>
                <div className="flex space-x-4">
                  <FormNumber
                    name="dataStart"
                    label="Desde:"
                    labelCol={{ span: 24 }}
                    rules={[
                      // { required: true, message: "Este campo es requerido" },
                      { type: "number", min: 1, message: "El número debe ser mayor a cero" },
                      { 
                        validator: (_, value) => 
                          value === undefined || Number.isInteger(value) 
                            ? Promise.resolve() 
                            : Promise.reject(new Error("El número debe ser un entero")) 
                      },
                    ]}
                    minNumber={1}
                    styleInput="w-full"
                  />
                  <FormNumber
                    name="dataEnd"
                    label="Hasta:"
                    labelCol={{ span: 24 }}
                    dependencies={["dataStart"]}
                    rules={[
                      // { required: true, message: "Este campo es requerido" },
                      { type: "number", min: 1, message: "El número debe ser mayor a cero" },
                      ...validatorHeader("dataStart"),
                    ]}
                    minNumber={1}
                    styleInput="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {!isWebSource && <Divider type="vertical" className="h-auto border border-gray_custom hidden md:block" />}
        <div className={isWebSource ? "w-full" : "w-full md:w-1/2"}>
          <p className="my-5 text-lg text-lightBlue2 font-bold">Descripción {isWebSource ? "del documento" : "de la hoja"}</p>
          {!isWebSource && (
            <FormSelectSearch
              name="municipalityColumn"
              label="Columna de Municipio:"
              options={[{ value: "0", label: "Ninguno" }, ...listColumns]}
              labelCol={{ span: 24 }}
              onChange={handleMunicipalityChange}
              rules={[{ required: true, message: "Este campo es requerido" }]}
            />
          )}
          {columnMunicipalitySelected && (
            <FormSelect
              name="municipalityCode"
              label="Codificación Municipio:"
              className="font-semibold text-color_text text-textTable"
              selectClass="formBgWhite"
              options={listCodification}
              labelCol={{ span: 24 }}
              rules={validatorCode()}
              tooltip="Seleccione el tipo de codificación del municipio:  DANE Completo: Incluye al inicio el código del departamento. DANE Sin Prefijo: No incluye el código del departamento."
            />
          )}
          {(!columnMunicipalitySelected || isWebSource) && (
            <FormTreeSelect
              name="municipality"
              label="Seleccione Municipio:"
              className="text-color_text treeMunicipality"
              labelCol={{ span: 24 }}
              tooltip="Si el archivo no contiene una columna 'Municipio', seleccione un municipio en este campo."
              treeData={listMunicipalities}
              maxTagCount={4}
            />
          )}
          {!isWebSource && (
            <FormSelectSearch
              name="dateColumn"
              label="Columna de Fecha de muestra:"
              className="font-semibold text-color_text"
              options={[{ value: "0", label: "Ninguno" }, ...listColumns]}
              labelCol={{ span: 24 }}
              onChange={handleDateChange}
              rules={[{ required: true, message: "Este campo es requerido" }]}
            />
          )}
          {(!columnDateSelected || isWebSource) && (
            <FormDate
              name="dateRange"
              label="Seleccione la fecha de muestra:"
              className="text-color_text"
              labelCol={{ span: 24 }}
              tooltip="Si el archivo no contiene una columna 'Fecha de muestra', seleccione una fecha en este campo."
              requiredActive={false}
            />
          )}
          <FormSelect
            name="keyWords"
            label="Palabras clave:"
            className="font-semibold text-color_text text-textTable select-tags"
            selectClass="formBgWhite"
            searchValue={searchValue}
            onSearch={setInputValue}
            labelCol={{ span: 24 }}
            mode="tags"
            onInputKeyDown={handleInputKeyDown}
            tokenSeparators={[","]}
            tooltip="Para agregar palabras clave, escriba la palabra y luego presione la tecla Enter"
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default SheetEditForm;
