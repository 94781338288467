import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import indicator from "../../api/indicator";
import token from "../../api/token";
import TimeSeriesChart from "../../components/atoms/Graphs/TimeSeriesChartMultiLines";
import FiltersInvestigationForm from "../../components/organisms/FiltersForm/FiltersInvestigationForm";
import DocumentRequest from "../../api/DocumentRequest";
import useApi from "../../api/useApi";
import transformToSelectArray from "../../helpers/transformToSelectArray";
import { useAuth } from "../../contexts/AuthContext";
import transformToDateFormat from "../../helpers/transformToDateFormat";

const Statement = () => {
  const { getDataIndicator } = indicator;
  const getMunicipalities = useApi(DocumentRequest.getMunicipalities);
  const [listDataSources, setListDataSources] = useState([]);
  const [listMunicipalities, setListMunicipalities] = useState([]);
  const [loadingChart, setLoadingChart] = useState(true);
  const [loadingForm, setLoadingForm] = useState(true);
  const { setMessage } = useAuth();

  const fetchData = async (listRequest) => {
    try {
      const requests = listRequest.map((val) => getDataIndicator(val));
      const responses = await Promise.all(requests);

      const responseData = responses.map((response) => {
        if (response.request.status === 401) {
          const acToken = token.refreshingToken();
          acToken.then(() => {
            fetchData();
          });
        }
        return response.data;
      });
      setListDataSources(responseData);
      setLoadingChart(false);
    } catch (error) {
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    setLoadingChart(true);
    setLoadingForm(true);
    getMunicipalities.request(52);
    const listRequest = [
      { pageNumber: 1, pageSize: 100, search: "total_population_men" },
      { pageNumber: 1, pageSize: 100, search: "total_population_women" },
    ];

    fetchData(listRequest);
  }, []);

  useEffect(() => {
    if (getMunicipalities.data) {
      const aux = transformToSelectArray(
        getMunicipalities.data,
        "municipio",
        "c_digo_dane_del_municipio"
      );
      const sortedData = [...aux].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setListMunicipalities(sortedData);
      setLoadingForm(false);
    }
  }, [getMunicipalities.data]);

  useEffect(() => {
    if (getMunicipalities.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos de los filtros.",
        code: getMunicipalities.error,
      });
      setLoadingForm(false);
    }
  }, [getMunicipalities.error]);

  const handleValues = (values) => {
    const { dateRange, municipalities, variable, indicators } = values;
    const startDate = transformToDateFormat(dateRange[0], "-", "dd/mm/yyyy");
    const endDate = transformToDateFormat(dateRange[1], "-", "dd/mm/yyyy");
    const listReq = indicators.map((ind) => ({
      pageNumber: 1,
      pageSize: 100,
      search: ind,
      startDate,
      endDate,
      variable,
      municipalities,
    }));
    fetchData(listReq);
  };

  return (
    <div className="flex justify-between mt-10">
      {!loadingChart && !loadingForm ? (
        <>
          <TimeSeriesChart listDataSources={listDataSources} />
          <div className="flex flex-col">
            <FiltersInvestigationForm
              listMunicipalities={listMunicipalities}
              handleValues={handleValues}
            />
          </div>
        </>
      ) : (
        <div className="flex w-full h-full items-center justify-center">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default Statement;
