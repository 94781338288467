// App.js
import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
import AppTitle from "../../atoms/Title/AppTitle";
import AppModal from "../../molecules/Modal/Modal";
// import ROUTES from "../../../constant/router";
import TableTemplate from "../TableTemplate";

const UsersList = () => {
  const dataSource = [
    {
      name: "John",
      lastName: "Doe",
      email: "john.doe@example.com",
      phone: "123-456-7890",
      gender: "male",
    },
    {
      name: "Jane",
      lastName: "Smith",
      email: "jane.smith@example.com",
      phone: "987-654-3210",
      gender: "female",
    },
    {
      name: "Alex",
      lastName: "Johnson",
      email: "alex.johnson@example.com",
      phone: "555-555-5555",
      gender: "non-binary",
    },
    {
      name: "Maria",
      lastName: "Garcia",
      email: "maria.garcia@example.com",
      phone: "777-777-7777",
      gender: "female",
    },
    {
      name: "Chris",
      lastName: "Lee",
      email: "chris.lee@example.com",
      phone: "333-333-3333",
      gender: "male",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const total = dataSource.length;
  const [loading, setLoading] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [data, setData] = useState(dataSource);

  //   const navigate = useNavigate();

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    setData(dataSource);
  }, [currentPage]);

  const handlePageChange = (page) => {
    // hacer la petición a la API para obtener los datos de la página
    setCurrentPage(page);
  };

  const onEdit = (key) => {
    // eslint-disable-next-line no-console
    console.log(key);
    /* const path = ROUTES.GENERAL.DOCUMENT_INFO.replace(":key", key);
    navigate(path); */
  };

  const closeModal = () => {
    setModalActive(false);
    setModalContent(null);
    setLoading(false);
  };
  // const showDataManagementUpdate = () => {
  //   setModalContent(<DataManagementUpload onCancel={closeModal} />);
  // };

  // const openModal = () => {
  //   setLoading(true);
  //   setModalActive(true);
  //   setModalContent(<DataManagementForm onNext={showDataManagementUpdate} />);
  // };

  // Las columnas de la tabla
  const columns = [
    {
      title: <span className="font-medium text-center text-xl ">Nombre</span>,
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text) => (
        <span className="  text-center text-sizeBase text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      width: 250,
    },
    {
      title: <span className="font-medium text-center text-xl">Apellido</span>,
      dataIndex: "lastName",
      key: "lastName",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">{text}</span>
      ),
      width: 150,
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Correo</span>,
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text) => (
        <span className=" rounded-md text-center text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Teléfono</span>,
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: <span className=" font-medium text-center text-xl ">Sexo</span>,
      dataIndex: "gender",
      key: "gender",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl ">Acciones</span>,
      key: "acciones",
      align: "center",
      ellipsis: true,
      render: (text, record) => (
        <div className="flex space-x-2 justify-center  ">
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
            icon={<EditOutlined />}
            onClick={() => onEdit(record.key)}
          />
        </div>
      ),
      width: 150,
      className: "border-b-0",
    },
  ];

  return (
    <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">
      <div className="w-10/12 mb-2 grid justify-items-end ">
        <Button
          shape="round"
          size="large"
          type="primary"
          className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
          loading={loading}
          onClick={() => {}}
        >
          Crear usuario
        </Button>
      </div>
      <div className=" w-11/12 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
        <div className="flex justify-center items-center mb-4">
          <AppTitle
            title="Datos Cargados"
            level={3}
            className="text-lightBlue2 font-bold  text-center !important"
          />
        </div>

        <TableTemplate
          enableScroll
          data={data}
          handlePageChange={handlePageChange}
          total={total}
          columns={columns}
          pageSize={pageSize}
          currentPage={currentPage}
          className=" "
        />
        <AppModal
          title="Importar Datos"
          open={modalActive}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 font-bold text-lg text-center !important"
        >
          {modalContent}
        </AppModal>
      </div>
    </div>
  );
};
export default UsersList;
