/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Spin } from "antd";
import Card from "../../components/atoms/Card/Card";
import AppTitle from "../../components/atoms/Title/AppTitle";
import indicator from "../../api/indicator";
import useApi from "../../api/useApi";
import TableTemplate from "../../components/templates/TableTemplate";
import { useAuth } from "../../contexts/AuthContext";
import document from "../../api/document";
import Flecha from "../../assets/image/flecha.svg";
import ItemButton from "../../components/atoms/Buttons/ItemButton";
import ModalConfirm from "../../components/molecules/Modal/ModalConfirm";
import ValidatePermissions from "../../helpers/ValidatePermissions";
import TabsIndicator from "./TabsIndicator";
// import sequentialNumberSelectArray from "../../helpers/sequentialNumberSelectArray";
import { addLevels } from "../../helpers/depthArray";
import RenderCards from "../../components/molecules/CardsTaxonomies/RenderCards";

const IndicatorsAdmin = () => {
  const navigate = useNavigate();
  const getIndicatorsTaxonomies = useApi(indicator.getIndicatorsTaxonomies);
  const getPostulatesIndicator = useApi(indicator.getPostulatesIndicator);
  /*   const getIndicatorTypesExtraction = useApi(
    document.getIndicatorTypesExtraction
  );
  const getCalculationSourceExtraction = useApi(
    document.getCalculationSourceExtraction
  ); */
  const updateRequestCalculation = useApi(document.updateRequestCalculation);
  const getObjectToCalculateIndicators = useApi(
    document.getObjectToCalculateIndicators
  );
  const updateIndicatorPriority = useApi(document.updateIndicatorPriority);
  const calculateIndicators = useApi(document.calculateIndicators);
  const [listGroupIndicator, setListGroupIndicator] = useState([]);
  const [loading, setLoading] = useState({
    taxonomies: false,
    dataIndicator: false,
    calculation: false,
    priority: false,
  });
  const [selected, setSelected] = useState({
    group: false,
    indicator: false,
  });
  const [idIndicator, setIdIndicator] = useState(null);
  const [idPostulation, setIdPostulation] = useState("");
  const [titleIndicator, setTitleIndicator] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [detailIndicator, setDetailIndicator] = useState({});
  const [stateIndicator, setStateIndicator] = useState("");
  const [activeTab, setActiveTab] = useState("indicator");
  const [modalActiveConfirm, setModalActiveConfirm] = useState(false);
  // const [listColumns, setListColumns] = useState(columns);
  const [metaPagination, setMetaPagination] = useState({
    totalRecords: 10,
    page: 1,
    pageSize: 10,
    totalPages: 1,
  });
  // const [optionsPriority, setOptionsPriority] = useState([]);
  const { setMessage } = useAuth();

  const handleClickVerified = (record) => {
    setActiveTab((previous) => {
      setDetailIndicator((prevState) => {
        if (previous === "calculationSource") {
          const name_source = record?.idCalculationSource;
          const auxFiltered = prevState.sources?.filter(
            (item) => item.formula_name === name_source
          );
          if (auxFiltered[0].state === "assigned") {
            setModalActiveConfirm(true);
            setIdPostulation(record?._id);
          } else {
            updateRequestCalculation.request(record?._id, {
              state: "assigned",
            });
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!prevState.fileId) {
            updateRequestCalculation.request(record?._id, {
              state: "assigned",
            });
          } else {
            setModalActiveConfirm(true);
            setIdPostulation(record?._id);
          }
        }
        return prevState;
      });
      return previous;
    });
  };

  const handleChangePriority = (value, record) => {
    setLoading((prev) => ({ ...prev, priority: true }));
    const auxPrior = {
      priority: value,
    };
    updateIndicatorPriority.request(record?._id, auxPrior);
  };

  const columns = [
    {
      title: (
        <div className="font-medium text-center text-base ">Priorización</div>
      ),
      dataIndex: "priority",
      key: "priority",
      align: "center",
      render: (text, record) => (
        <Select
          className="select-no-style"
          options={/* optionsPriority */ []}
          defaultValue={text}
          value={text}
          onChange={(val) => handleChangePriority(val, record)}
        />
      ),
      className: "  ",
    },
    {
      title: (
        <div className="font-medium text-center text-base">
          Nombre de la columna
        </div>
      ),
      dataIndex: "columnName",
      key: "columnName",
      align: "center",
      render: (text) => (
        <div className="rounded-md text-center text-black_custom">{text}</div>
      ),
      className: " ",
    },
    {
      title: (
        <div className="font-medium text-center text-base">
          Código del archivo
        </div>
      ),
      dataIndex: "_id",
      key: "_id",
      align: "center",
      render: (text) => (
        <div className="rounded-md text-center text-black_custom">
          {text && text.slice(-6)}
        </div>
      ),
      className: " ",
    },
    {
      title: <div className="font-medium text-center text-base">Link</div>,
      dataIndex: "fileId",
      key: "fileId",
      align: "center",
      render: (text) => (
        <button
          type="button"
          className="underline text-lightBlue2"
          onClick={() => navigate(`/document/${text}`)}
        >
          Link del archivo
        </button>
      ),
      className: " ",
    },
    {
      title: <div className="font-medium text-center text-base">Autor</div>,
      dataIndex: "userDetails",
      key: "userDetails",
      align: "center",
      render: (value) => (
        <div className="rounded-md text-center text-black_custom">{`${value?.firstName} ${value?.lastName}`}</div>
      ),
      className: " ",
    },
    {
      title: <div className="font-medium text-center text-base ">Estado</div>,
      key: "acciones",
      align: "center",

      render: (_, record) => (
        <ValidatePermissions
          func="oneButton"
          permission="calculate"
          modulePermission="indicator"
        >
          {record.state === "assigned" ? (
            <strong className="text-lightBlue2 font-semibold">ASIGNADO</strong>
          ) : (
            <ItemButton
              text="Asignar"
              disabled
              className="bg-dark_gray"
              onClick={() => handleClickVerified(record)}
            />
          )}
        </ValidatePermissions>
      ),
    },
  ];
  /* <ItemButton
    text="Asignar"
    onClick={() => handleClickVerified(record)}
  /> */

  const handlePageChange = (page) =>
    setMetaPagination((prev) => ({ ...prev, page }));

  /*   useEffect(() => {
    if (idIndicator) {
      getCalculationSourceExtraction.request(
        idIndicator,
        metaPagination.pageSize,
        metaPagination.page
      );
    }
  }, [metaPagination.page]); */

  useEffect(() => {
    setLoading((prev) => ({ ...prev, taxonomies: true }));
    getIndicatorsTaxonomies.request();
  }, []);

  useEffect(() => {
    if (getPostulatesIndicator.data) {
      const dataIndicator = getPostulatesIndicator.data;
      setLoading((prev) => ({ ...prev, dataIndicator: false }));
      const dataTable = dataIndicator.data || [];
      setDataSource(dataTable);
    }
  }, [getPostulatesIndicator.data]);

  useEffect(() => {
    if (getIndicatorsTaxonomies.data) {
      setLoading((prev) => ({ ...prev, taxonomies: false }));
      const auxTree = getIndicatorsTaxonomies.data?.data;
      const auxAddingLevels = addLevels(auxTree);
      const sortedIndicator = [...auxAddingLevels].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setListGroupIndicator(sortedIndicator);
      getIndicatorsTaxonomies.setData();
    }
    /*     if (getCalculationSourceExtraction.data) {
      const dataIndicator = getCalculationSourceExtraction.data;
      setLoading((prev) => ({ ...prev, dataIndicator: false }));
      const dataTable = dataIndicator.data || [];
      const detailInd = dataIndicator.metadata;
      setDataSource(dataTable);
      setDetailIndicator(detailInd.calculationSource);
      setStateIndicator(detailInd.indicatorType?.state);
      const { actualPage, totalPages, totalRecords } = dataIndicator.meta;
      setOptionsPriority(sequentialNumberSelectArray(totalRecords));
      setMetaPagination((prev) => ({
        ...prev,
        page: actualPage,
        totalPages,
        totalRecords,
      }));
      getIndicatorTypesExtraction.setData();
    } */

    if (updateRequestCalculation.data) {
      getPostulatesIndicator.request(idIndicator);
      /* getCalculationSourceExtraction.request(
        idIndicator,
        metaPagination.pageSize,
        metaPagination.page
      ); */
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La postulación se asigno correctamente.",
      });
      updateRequestCalculation.setData();
    }
    if (calculateIndicators.data) {
      setLoading((prev) => ({ ...prev, calculation: false }));
      setStateIndicator("Calculado");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El indicador se calculo exitosamente.",
      });
      calculateIndicators.setData();
    }
    if (getObjectToCalculateIndicators.data) {
      calculateIndicators.request(getObjectToCalculateIndicators.data);
      getObjectToCalculateIndicators.setData();
    }
    if (updateIndicatorPriority.data) {
      getPostulatesIndicator.request(idIndicator);

      /*  getCalculationSourceExtraction.request(
        idIndicator,
        metaPagination.pageSize,
        metaPagination.page
      ); */
      setLoading((prev) => ({ ...prev, priority: false }));
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Prioridad asignada exitosamente.",
      });
      updateIndicatorPriority.setData();
    }
  }, [
    getIndicatorsTaxonomies.data,
    // getIndicatorTypesExtraction.data,
    // getCalculationSourceExtraction.data,
    updateRequestCalculation.data,
    calculateIndicators.data,
    getObjectToCalculateIndicators.data,
    updateIndicatorPriority.data,
  ]);

  useEffect(() => {
    if (getIndicatorsTaxonomies.error) {
      setLoading((prev) => ({ ...prev, taxonomies: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al obtener los indicadores. Por favor recargue la pagina.",
        code: getIndicatorsTaxonomies.error,
      });
    }
    /*     if (getCalculationSourceExtraction.error) {
      setLoading((prev) => ({ ...prev, dataIndicator: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al obtener los datos del indicador. Por favor recargue la pagina.",
        code: getIndicatorTypesExtraction.error,
      });
    } */
    /*    if (getIndicatorTypesExtraction.error) {
      setLoading((prev) => ({ ...prev, dataIndicator: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al obtener los datos del indicador. Por favor recargue la pagina.",
        code: getIndicatorTypesExtraction.error,
      });
    } */
    if (updateRequestCalculation.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar asignar la postulación. Por favor vuelva a intentarlo mas tarde.",
        code: updateRequestCalculation.error,
      });
    }
    if (calculateIndicators.error) {
      setLoading((prev) => ({ ...prev, calculation: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar calcular el indicador. Por favor vuelva a intentarlo mas tarde.",
        code: calculateIndicators.error,
      });
    }
    if (getObjectToCalculateIndicators.error) {
      setLoading((prev) => ({ ...prev, calculation: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar calcular el indicador. Por favor vuelva a intentarlo mas tarde.",
        code: getObjectToCalculateIndicators.error,
      });
    }
    if (getPostulatesIndicator.error) {
      setLoading((prev) => ({ ...prev, dataIndicator: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al obtener los datos del indicador. Por favor recargue la pagina.",
        code: getPostulatesIndicator.error,
      });
    }
  }, [
    getIndicatorsTaxonomies.error,
    // getIndicatorTypesExtraction.error,
    // getCalculationSourceExtraction.error,
    updateRequestCalculation.error,
    calculateIndicators.error,
    getObjectToCalculateIndicators.error,
    getPostulatesIndicator.error,
  ]);

  const handlerClickGroup = (name) =>
    setSelected({ group: name, indicator: false });

  const handlerClickIndicator = (ind) => {
    setSelected((prev) => ({ ...prev, indicator: true }));
    setTitleIndicator(ind?.name);
    setIdIndicator(ind?._id);
    setLoading((prev) => ({ ...prev, dataIndicator: true }));
    getPostulatesIndicator.request(ind?._id);
    /*     getCalculationSourceExtraction.request(
      ind?._id,
      metaPagination.pageSize,
      metaPagination.page
    ); */
  };

  const confirmCloseModal = () => {
    updateRequestCalculation.request(idPostulation, {
      state: "assigned",
    });
    setModalActiveConfirm(false);
  };

  const returnStateCalculation = () => {
    if (loading.calculation || stateIndicator === "processing")
      return (
        <strong className="font-medium text-colorBorder">EN PROCESO</strong>
      );
    if (stateIndicator === "no calculated")
      return (
        <strong className="font-medium text-colorBorder">NO CALCULADO</strong>
      );
    return <strong className="font-medium text-colorBorder">CALCULADO</strong>;
  };

  const returnValueFormula = () => {
    if (activeTab === "indicator") {
      if (detailIndicator.fileId) {
        return (
          <strong className="font-medium text-colorBorder">ASIGNADO</strong>
        );
        /* (
          <button
            type="button"
            className="underline text-lightBlue2 ml-1"
            onClick={() => navigate(`/document/${detailIndicator.fileId}`)}
          >
            Link del archivo
          </button>
        ); */
      }
      return <strong className="font-medium text-colorBorder">ASIGNADO</strong>;
    }
    return detailIndicator?.formula;
  };

  const renderDetailsIndicator = () => {
    if (!selected.group) {
      return (
        <div className="flex flex-col-reverse md:flex-col h-full w-full justify-center items-start ">
          <img src={Flecha} className="rotate-[270deg] md:rotate-0" alt="" />
          <p className="ml-20">
            Seleccione el grupo de indicadores que desea visualizar
          </p>
        </div>
      );
    }
    if (!selected.indicator) {
      return (
        <div className="flex flex-col-reverse md:flex-col h-full w-full justify-center items-start ">
          <img src={Flecha} className="rotate-[270deg] md:rotate-0" alt="" />
          <p className="ml-20">Seleccione el indicador que desea visualizar</p>
        </div>
      );
    }
    if (selected.indicator && !loading.dataIndicator) {
      return (
        <>
          <TabsIndicator
            detailIndicator={detailIndicator}
            titleIndicator={titleIndicator}
            returnStateCalculation={returnStateCalculation}
            returnValueFormula={returnValueFormula}
          />

          <Card className="ml-1 mt-2">
            {/* {!loading.priority ? ( */}
            <TableTemplate
              rowKey="id"
              data={dataSource}
              columns={columns}
              className=" "
              total={metaPagination.totalRecords}
              pageSize={metaPagination.pageSize}
              currentPage={metaPagination.page}
              handlePageChange={handlePageChange}
            />
            {/*  ) : (
              <div className="flex justify-center items-center h-[300px]">
                <Spin />
              </div>
            )} */}
          </Card>
        </>
      );
    }
    return <Spin className="mt-20" />;
  };
  return (
    <div className="flex flex-col flex-auto items-start justify-start w-full p-10">
      <div className="flex w-full h-[50px] justify-center items-center">
        <AppTitle
          title={titleIndicator}
          level={4}
          className="text-lightBlue2 font-bold text-center !important"
        />
      </div>
      <div className="flex w-full flex-col-reverse md:flex-row">
        <Card className="flex flex-none w-full md:w-1/3 flex-col mt-5 md:mt-0 ml-0 md:ml-5">
          <span className="text-center mb-10">Indicadores Trazadores</span>
          <RenderCards
            list={listGroupIndicator}
            handlerClickIndicator={handlerClickIndicator}
            handlerClickGroup={handlerClickGroup}
            loading={loading.taxonomies}
          />
        </Card>
        <div className="flex flex-1 flex-col w-full justify-start ml-0 md:ml-5">
          {renderDetailsIndicator()}
        </div>
      </div>
      <ModalConfirm
        okText="Confirmar"
        text="Esta formula ya tiene una columna asignada. ¿Deseas reemplazarla?"
        modalActive={modalActiveConfirm}
        closeModal={() => setModalActiveConfirm(false)}
        handleOK={confirmCloseModal}
        handleCancel={() => setModalActiveConfirm(false)}
      />
    </div>
  );
};

export default IndicatorsAdmin;
