import React from "react";
import AppTable from "../../organisms/AppTable/AppTable";
import AppPagination from "../../organisms/AppTable/AppPagination";

const TableTemplate = ({
  data,
  columns,
  currentPage,
  total,
  pageSize = 10,
  handlePageChange,
  enableScroll,
  className = "md:max-w-[600px] md:w-full lg:max-w-[920px] 2xl:max-w-[calc(100vw-120px)] 2xl:px-10",
  isClickRowActive = false,
  onSelectRow,
  rowKey,
  onlyView,
  breakWords,
  handleChange = () => {},
  hasPagination = true,
}) => (
  <div className={`max-w-[calc(100vw-20px)] ${className}`}>
    <AppTable
      data={data}
      columns={columns}
      enableScroll={enableScroll}
      onSelectRow={isClickRowActive ? onSelectRow : () => {}}
      isClickRowActive={isClickRowActive}
      rowKey={rowKey}
      onlyView={onlyView}
      breakWords={breakWords}
      handleChange={handleChange}
    />
    {hasPagination ? (
      <AppPagination
        page={currentPage}
        total={total}
        pageSize={pageSize}
        onChange={handlePageChange}
      />
    ) : null}
  </div>
);
export default TableTemplate;
