/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from "react";
import { ConfigProvider, TreeSelect, Form, message } from "antd";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";
import AppText from "../../atoms/Text/AppText";
import SubmitButton from "../../atoms/Buttons/SubmitButton";

// import FindParent from "../../../helpers/FindParent";
const { SHOW_PARENT } = TreeSelect;


const AssociateIndicatorForm = ({ columnName, onHandleSubmit, listGroupIndicator, initialValues }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState([]);
  const [parentValue, setParentValue] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        indicator: initialValues.indicator,
      });
      setValue(initialValues.indicator);
      setParentValue(initialValues.parentValues);
      setIsEdit(true);
    }else{
      form.resetFields();
      setValue([]);
      setParentValue([]);
      setIsEdit(false);
    }
  }, [initialValues, form]);

  const onChange = (newValue, label, extra) => {
    const { triggerValue, checked, triggerNode } = extra;
  
    if (triggerNode) {
      
      const parentId = triggerNode.props.isLeaf ?  triggerNode.props.parent:triggerNode.props.group;
  
      if (checked) {
        const parentIdExists = parentValue.some(item => item.parent === parentId);
  
        if (parentIdExists) {
          // Si el padre ya existe y está marcado, muestra un error
          message.error("No se puede seleccionar más de un indicador o fuente por grupo");
          form.setFieldsValue({ indicator: value });
          // eslint-disable-next-line no-param-reassign
          newValue = value.filter(item => item !== triggerValue);
          
        } else {
          // validar si es hoja
          const {isLeaf} = triggerNode.props;
          const elementType = isLeaf ? "calculationSource":"indicator" ;
          // Agregar el nuevo padre e hijo si no existen
          setParentValue(prev => [...prev, {parent: parentId, children: triggerValue,type: elementType}]);
          setValue(prev => [...prev, triggerValue]);
        }
      } else {
        // Remover padre e hijo si desmarcado
        setParentValue(prev => prev.filter(item => item.parent !== parentId));
        setValue(prev => prev.filter(item => item !== triggerValue));
      }
    } else {
      setParentValue([]); // Asegúrate de resetear a un arreglo vacío, no a null
    }
  
  };
  
  


  const onSubmit = (values) => {
    onHandleSubmit(values, parentValue,isEdit);
  };
            
  return (
    <ConfigProvider
      theme={{
        components: {
          "TreeSelect": selectWhiteStyle,
          "Select": selectWhiteStyle,
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        className="font-Poppins"
        onFinish={(values) => { onSubmit(values) }}
      >
        <div className=" flex flex-col items-center ">
          <div className=" p-2 flex flex-col">
            <div className=" mb-2 grid justify-items-center pt-4 ">
              <AppText
                title={`Asocie la columna "${columnName}" con un indicador o su variable de cálculo`}
                type="secondary"
                className="text-darkAquamarineBlue font-semibold text-xl"
              />

            </div>

            <Form.Item
              name="indicator"
              colon={false}
              label={<span className="font-Poppins font-light  text-color_text text-center "> Indicadores :</span>}
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Por favor seleccione un grupo de indicador " }]}
              className="font-Poppins  text-color_text"
            >
              < TreeSelect
                treeData={listGroupIndicator}
                // value={value}
                value={value}
                // defaultValue={value}
                onChange={onChange}
                treeCheckable={true}
                showSearch={false}
                // treeCheckStrictly={true}
                treeExpandAction="doubleClick"
                placeholder="Seleccione un indicador"
                className="font-Poppins font-medium text-color_text"
                allowClear
                showCheckedStrategy={ SHOW_PARENT }
                // treeLine
                
              />

            </Form.Item>
          </div>
          <Form.Item>
            <div className="flex justify-center text-center pt-2">
              <SubmitButton
                nameButton="Guardar"
                // loading={loading}
                form={form}
                htmlType="submit"
              />
            </div>
          </Form.Item>

        </div>
      </Form>
    </ConfigProvider>

  )
};

export default AssociateIndicatorForm;
    