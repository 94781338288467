import { useState } from "react";
import token from "./token";

export default (apiFunc) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  async function refreshToken() {
    // Ensure this function returns a promise that resolves when the token is refreshed
    return new Promise((resolve, reject) => {
      const actToken = token.refreshingToken();
      actToken
        .then((tok) => {
          if (tok) {
            resolve();
          } else {
            reject(new Error("Token refreshing failed"));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  const request = async (...args) => {
    setLoading(true);
    setError(null);
    setData(null);
    try {
      const result = await apiFunc(...args);
      if (
        result.request.status === 200 ||
        result.request.status === 201 ||
        result.request.status === 202
      ) {
        setData(result.data);
      } else {
        setError(result.response.data);
        if (result.request.status === 401) {
          try {
            await refreshToken();
            const result2 = await apiFunc(...args);
            setData(result2.data);
          } catch (err) {
            setError({ message: err.message || "Unexpected Error!" });
          }
        }
      }
    } catch (err) {
      setError({ message: err.message || "Unexpected Error!" });
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request,
    setData,
  };
};
