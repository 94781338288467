import axios from "axios";
import Config from "../config";
import token from "./token";

const instance = axios.create({
  baseURL: `${Config.urlBackend}`,
  timeout: Config.timeOut,
  headers: {
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json",
  },
});
const instance2 = axios.create({
  baseURL: `${Config.urlBackend}`,
  timeout: Config.timeOut,
  responseType: "blob",
});

const instance3 = axios.create({
  // baseURL: `${Config.urlApiDatosAbiertos}`,
  timeout: Config.timeOut,
  headers: {
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const auxToken = token.getToken();
    config.headers.setAuthorization(`Bearer ${auxToken}`, true);
    return config;
  },
  (error) => error
);

// Add a request interceptor
instance2.interceptors.request.use(
  (config) => {
    const auxToken = token.getToken();
    config.headers.setAuthorization(`Bearer ${auxToken}`, true);
    return config;
  },
  (error) => error
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.config?.headers?.Authorization === "Bearer undefined" &&
      token.getToken()
    ) {
      window.location.reload();
    }
    return error;
  }
);

export { instance, instance2, instance3 };
