import { Layout } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import LoginTemplate from "../../components/templates/LoginTemplate/LoginTemplate";
import auth from "../../api/auth";
import useApi from "../../api/useApi";
import token from "../../api/token";
import { useAuth } from "../../contexts/AuthContext";

const { Content } = Layout;

function LoginPage() {
  const [loading, setLoading] = useState({ login: false, sendEmail: false });
  const sendEmail = useApi(auth.recoveryPswNoAuth);
  const loginRequest = useApi(auth.login);

  const { setCurrentUser, setMessage } = useAuth();

  // const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [, setUser] = useLocalStorage("user")

  const handleFormSubmit = async (e) => {
    loginRequest.request({ email: e.email, password: e.password });
    setLoading((prev) => ({ ...prev, login: true }));
  };
  useEffect(() => {
    if (loginRequest.data) {
      const { accessToken, refreshToken } = loginRequest.data;
      token.saveRefreshToken(refreshToken, 7);
      token.saveToken(accessToken);
      const tokenDecode = jwtDecode(accessToken);
      const { sub, email, firstName, lastName, role } = tokenDecode;
      setCurrentUser({ sub, email, firstName, lastName, role });
      navigate("/");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Su inicio de sesión fue exitoso.",
      });
      loginRequest.setData();
      setLoading((prev) => ({ ...prev, login: false }));
    }
    if (sendEmail.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description:
          "Si el correo electrónico esta registrado se enviara un correo para recuperar su contraseña",
      });
      setLoading((prev) => ({ ...prev, sendEmail: false }));
      sendEmail.setData();
    }
  }, [loginRequest.data, sendEmail.data]);

  useEffect(() => {
    if (loginRequest.error) {
      if (loginRequest.error === "User is pending") {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "El usuario esta pendiente de aprobación por el administrador.",
        });
      } else {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Hubo un error al intentar iniciar sesión revise sus credenciales.",
          // code: loginRequest.error,
        });
        // setLoading((prev) => ({ ...prev, login: false }));
      }
      setLoading((prev) => ({ ...prev, login: false }));
    }
    if (sendEmail.error) {
      setMessage({
        type: "success",
        title: "Resultado",
        description:
          "Si el correo electrónico esta registrado se enviara un correo para recuperar su contraseña",
        code: sendEmail.error,
      });
      setLoading((prev) => ({ ...prev, sendEmail: false }));
    }
  }, [loginRequest.error, sendEmail.error]);

  const onSendEmail = (data) => {
    setLoading((prev) => ({ ...prev, sendEmail: true }));
    sendEmail.request({ email: data.email });
  };

  return (
    <Layout>
      <Content>
        <LoginTemplate
          loading={loading.login}
          loadingRecovery={loading.sendEmail}
          onSendEmail={onSendEmail}
          onSubmitForm={(e) => handleFormSubmit(e)}
        />
      </Content>
    </Layout>
  );
}

export default LoginPage;
