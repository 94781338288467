import React, { useState, useEffect } from "react";
import { Tabs, Button, Spin } from "antd";
import axios from "axios";
// import { useParams } from "react-router-dom";
// import DataPreviewTemplate from "../../components/templates/DataPreviewTemplate/DataPreviewTemplate";
import document from "../../../api/document";
import useApi from "../../../api/useApi";
import { useAuth } from "../../../contexts/AuthContext";
import findElementInArray from "../../../helpers/findElementInArray";
import ValidatePermissions from "../../../helpers/ValidatePermissions";
import AppModal from "../../molecules/Modal/Modal";
import transformToDateFormat from "../../../helpers/transformToDateFormat";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import DocumentUploadSteps from "../../organisms/DocumentUpload/DocumentUploadSteps";
import ModalConfirm from "../../molecules/Modal/ModalConfirm";

import DocumentList from "./DocumentList";
import DocumentIndex from "./DocumentIndex";

const listCodification = [
  {
    value: "1",
    label: "DANE COMPLETO",
  },
  {
    value: "2",
    label: "DANE SIN PREFIJO",
  },
];
const DocumentTabs = () => {
  // const
  const docTypeWeb = "6647566b36ea2fe2ec662a9a";
  const { setMessage } = useAuth();

  const [modalActive, setModalActive] = useState(false);
  const [modalActiveConfirm, setModalActiveConfirm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState({ general: false, steps: false, table: false });
  const [file, setFile] = useState();
  // const [isWebSource, setIsWebSource] = useState(false);
  const [docTypes, setDocTypes] = useState();
  const [action, setAction] = useState("");
  // const [docTypeWeb, setDocTypeWeb] = useState(null);
  // const [urlSource, setUrlSource] = useState(null);
  const [idDocument, setIdDocument] = useState(null);
  const [reloadListDocs, setReloadListDocs] = useState(false);

  // request
  const createdWebSource = useApi(document.postWebSource);
  const getUrlUploadDoc = useApi(document.getUrlUploadDoc);
  const updateDescriptionDoc = useApi(document.updateDescriptionDoc);
  const getDocTypes = useApi(document.getDocumentTypes);
  // const params = useParams();
  // const [loading, setLoading] = useState(false);
  // const getDescriptionDoc = useApi(document.getDescriptionDoc);

  // useEffect(() => {
  //   getDescriptionDoc.request(params?.key);
  //   setLoading(true);
  // }, []);

  // useEffect(() => {
  //   if (getDescriptionDoc.data) {
  //     const aux = getDescriptionDoc.data;
  //     setLoading(false);
  //     setDataDescription(aux);
  //     if (aux.fileExtension === "web") {
  //       setIsWebSource(true);
  //     } else {
  //       setIsWebSource(false)
  //     }
  //   }
  //   getDescriptionDoc.setData();
  // }, [getDescriptionDoc.data]);

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página web
    getDocTypes.request();
    setLoading((prev) => ({ ...prev, list: true }));
  }, []);

  const { permissions } = useAuth();
  const hasPermissionCreate = findElementInArray(
    permissions,
    "document_management.create"
  );
  const rendeDocumentList = (view) => (
    <div className="shadow-body ml-[3px] relative top-[-1px]">
      <DocumentList documentView={view} reloadData={reloadListDocs} setReloadData={setReloadListDocs} />
    </div>
  );

  const changeTab = () => {
    setReloadListDocs(true);
  };

  const items = [
    {
      key: "1",
      label: "Todos",
      children: rendeDocumentList("All"),
      // <div className="shadow-body ml-[3px] relative top-[-1px]">
      //   <DocumentList documentView="All" reloadData={reloadListDocs} />
      // </div>
    },
    {
      key: "2",
      label: "Mis Archivos",
      children: rendeDocumentList("MyDocuments"),
      // <div className="shadow-body ml-[3px] relative top-[-1px]">
      //   <DocumentList documentView="MyDocuments" reloadData={reloadListDocs} />
      // </div>
    },
    {
      key: "3",
      label: "Compartidos",
      children: rendeDocumentList("shared"),
      // <div className="shadow-body ml-[3px] relative top-[-1px]">
      //   <DocumentList documentView="shared" reloadData={reloadListDocs} />
      // </div>
    },
    {
      key: "4",
      label: "Búsqueda indexada por taxonomía",
      children: <DocumentIndex />,
    },
  ];

  // upload document
  const onClickNext = (dataFile) => {
    if (dataFile.sourceType === docTypeWeb) {
      setLoading((prev) => ({ ...prev, steps: true }));

      const { sourceType, urlWebPage } = dataFile;

      const auxData = {
        urlWebSource: urlWebPage,
        docType: sourceType,
        fileExtension: "web",
      };
      // setIsWebSource(true);
      // setTypeSource(dataFile.sourceType);
      createdWebSource.request(auxData);
      // setCurrentStep(1);
      // setLoading((prev) => ({ ...prev, steps: false }));
    } else {
      // setIsWebSource(false);
      const { name, size } = dataFile.upload;
      setFile(dataFile.upload.originFileObj);
      setLoading((prev) => ({ ...prev, steps: true }));
      const arraySplit = name.split(".");
      const lengthArr = arraySplit.length;
      const ext = arraySplit[lengthArr - 1];
      getUrlUploadDoc.request(name, dataFile.sourceType, ext, size);
    }
  };
  const handlerSubmitDescription = (val) => {
    const { name, description, municipalityColumn, dateColumn } = val;
    const munColumnAux = municipalityColumn || null;
    const dateColumnAux = dateColumn || null;
    const municipality = val?.municipality || [];
    const municipalityCode = val?.municipalityCode || null;
    const dateRange = val?.dateRange
      ? transformToDateFormat(val?.dateRange)
      : null;
    const urlWebSource = val?.urlWebSource || null;
    const keyWords = val?.keyWords || [];
    const cutOffDate = val?.cutOffDate
      ? transformToDateFormat(val?.cutOffDate)
      : null;

    const auxData = {
      name,
      description,
      urlWebSource,
      keyWords,
      cutOffDate,
      locationData: {
        municipalityColumn: munColumnAux,
        municipalityCode,
        municipality,
        dateColumn: dateColumnAux,
        dateRange,
      },
    };
    updateDescriptionDoc.request(idDocument, auxData);
    setLoading((prev) => ({ ...prev, general: true }));
    setLoading((prev) => ({ ...prev, descriptionModal: true }));
    
  };

  const catchErrorUploadFile = () => {
    setMessage({
      type: "error",
      title: "Error",
      description:
        "Hubo un problema con la subida del archivo. Revise que sea el tipo de fuente adecuado y subirlo nuevamente.",
    });
    setLoading((prev) => ({ ...prev, steps: false }));

    setCurrentStep(0);
  };
  useEffect(() => {
    if (createdWebSource.data) {
      const { id } = createdWebSource.data;
      setIdDocument(id);
      setCurrentStep(1);
      setLoading((prev) => ({ ...prev, steps: false }));
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La url de la página web se ha guardado correctamente.",
      });
      setReloadListDocs(true);
      createdWebSource.setData();
      // setIsWebSource(true);
    }
    if (updateDescriptionDoc.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description:
          "La descripción del documento se ha actualizado correctamente",
      });
      setModalActive(false);

      setTimeout(() => {
        setReloadListDocs(true);
        setLoading((prev) => ({ ...prev, general: false }));

      }, 1000);

      // setLoading((prev) => ({ ...prev, descriptionModal: false }));

      updateDescriptionDoc.setData();
    }
  }, [createdWebSource.data, updateDescriptionDoc.data]);

  useEffect(() => {
    if (getUrlUploadDoc.data) {
      const { urlRowData, id } = getUrlUploadDoc.data;
      setIdDocument(id);
      const config = {
        headers: {
          Accept: "application/json; charset=utf-8",
          "Content-Type": file.type,
        },
      };
      try {
        const response = axios.put(urlRowData, file, config);
        response
          .then((res) => {
            if (res.status === 200) {
              // TODO: hacer que cuando sea multihoja la respuesta se demore 5-10 segundos mas
              setCurrentStep(1);
              setLoading((prev) => ({ ...prev, steps: false }));

              setMessage({
                type: "success",
                title: "Resultado",
                description: "El archivo se ha subido correctamente.",
              });
              setReloadListDocs(true);
            } else {
              catchErrorUploadFile();
            }
          })
          .catch(() => {
            catchErrorUploadFile();
          });
      } catch (error) {
        catchErrorUploadFile();
      }
      getUrlUploadDoc.setData();
    }
    if (getDocTypes.data) {
      const arraySelect = transformToSelectArray(
        getDocTypes.data,
        ["name", "document_type", "documentType"],
        "id"
      );
      setDocTypes(arraySelect);
      getDocTypes.setData();
    }
  }, [getUrlUploadDoc.data, getDocTypes.data]);

  useEffect(() => {
    if (createdWebSource.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la url de la página web.",
      });
      setCurrentStep(0);
      setLoading((prev) => ({ ...prev, steps: false }));
    }

    if (updateDescriptionDoc.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Por favor inténtelo nuevamente.",
      });
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
    }

    if (getUrlUploadDoc.error) {
      catchErrorUploadFile();
    }
  }, [updateDescriptionDoc.error, getUrlUploadDoc.error]);

  const validateAuxItems = () => {
    if (hasPermissionCreate) {
      return items;
    }
    return items;
  };
  const openModal = () => {
    setAction("upload");
    setLoading((prev) => ({ ...prev, general: true }));
    setModalActive(true);
    setCurrentStep(0);
  };

  // modal operations

  const closeConfirmModal = () => setModalActiveConfirm(false);

  const confirmCloseModal = () => {
    setModalActive(false);
    setModalActiveConfirm(false);
    setLoading((prev) => ({ ...prev, general: false }));
    setLoading((prev) => ({ ...prev, steps: false }));
  };
  const closeModal = () => {
    if (currentStep === 1) {
      setModalActiveConfirm(true);
    } else {
      setModalActive(false);
      setLoading((prev) => ({ ...prev, general: false }));
      setLoading((prev) => ({ ...prev, steps: false }));
    }
  };

  const renderModal = () => {
    if (modalActive) {
      return (
        <AppModal
          open={modalActive}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 text-lg text-center w-full lg:w-1/2"
        >
          <DocumentUploadSteps
            onClickNext={onClickNext}
            current={currentStep}
            loadingSteps={loading.steps}
            listCodification={listCodification}
            docTypes={docTypes}
            action={action}
            handlerSubmit={handlerSubmitDescription}
            docTypeWeb={docTypeWeb}
          />
        </AppModal>
      );
    }
    return null;
  };
  return (
    // !loading ? (
    //   <div className="min-h-screen flex flex-col pt-10 px-2 md:px-20">
    //     <Tabs type="card" defaultActiveKey="1" items={validateAuxItems()} />
    //   </div>
    // ) : (
    //   <div className="flex justify-center items-center h-60">
    //     <Spin />
    //   </div>
    // )
    <div className="min-h-screen flex flex-col pt-10 px-2 md:px-20">
      <div className=" mb-2 grid justify-items-end ">
        <ValidatePermissions
          func="oneButton"
          permission="create"
          modulePermission="document_management"
        >
          <Button
            shape="round"
            size="large"
            type="primary"
            className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
            loading={loading.general}
            onClick={openModal}
          >
            Cargar Datos
          </Button>
        </ValidatePermissions>
      </div>
      {!loading.general ? (
        <Tabs
          type="card"
          defaultActiveKey="1"
          items={validateAuxItems()}
          onChange={changeTab}
        />
      ) : (
        <div className="flex justify-center items-center h-60">
          <Spin />
        </div>
      )}
      {renderModal()}
      <ModalConfirm
        centered
        okText="Aceptar"
        handleCancel={closeConfirmModal}
        handleOK={confirmCloseModal}
        closeModal={closeConfirmModal}
        modalActive={modalActiveConfirm}
        text="¿Estas seguro que deseas cerrar el proceso de descripción del archivo? Si lo haces igual puedes retomar el proceso después"
      />
    </div>
  );
};

export default DocumentTabs;
