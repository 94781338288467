import React from "react";
import { Form } from "antd";
import AppTreeSelect from "../../atoms/TreeSelect/AppTreeSelect";
/* eslint-disable react/jsx-props-no-spreading */

const FormTreeSelect = ({
  name,
  label,
  styleItem = "",
  rules,
  labelCol = "",
  treeData,
  ...props
}) => {
  const combinedClassName = `font-Poppins font-light text-black_custom  ${styleItem}`;
  return (
    <Form.Item
      labelCol={labelCol}
      colon={false}
      name={name}
      label={
        label ? (
          <span className="font-Poppins font-light text-color_text pl-2">
            {label}
          </span>
        ) : null
      }
      rules={rules}
      className={combinedClassName}
    >
      <AppTreeSelect
        {...props}
        treeData={treeData}
        style={{
          width: "100%",
        }}
        allowClear
      />
    </Form.Item>
  );
};

export default FormTreeSelect;
