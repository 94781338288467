import { instance3 as client } from "./client_document";
import api from ".";

const dataSource = [
  {
    key: "1",
    name: "Informe sobre Violencia Doméstica en Nariño 2022",
    description: "Informe sobre Violencia Doméstica en Nariño 2022",
    document_type: "CSV",
    size: "1.5 MB",
    dateUploaded: "hace 2 días",
  },
  {
    key: "2",
    name: "Estudio sobre la Prevalencia de Enfermedades Cardiovasculares en Nariño",
    description:
      "Estudio sobre la Prevalencia de Enfermedades Cardiovasculares en Nariño",
    document_type: "XLS",
    size: "2.5 MB",
    dateUploaded: "hace 5 días",
  },
  {
    key: "3",
    name: "Movilidad y Migración Interna en Nariño: Un Estudio Demográfico",
    description:
      "Movilidad y Migración Interna en Nariño: Un Estudio Demográfico",
    document_type: "CSV",
    size: "3.5 MB",
    dateUploaded: "hace 7 días",
  },
  {
    key: "4",
    name: "Tendencias de la Violencia Juvenil en Nariño",
    description: "Tendencias de la Violencia Juvenil en Nariño",
    document_type: "XLS",
    size: "4.5 MB",
    dateUploaded: "hace 10 días",
  },
  {
    key: "5",
    name: "Estudio sobre la Prevalencia de Enfermedades Cardiovasculares en Nariño",
    description:
      "Estudio sobre la Prevalencia de Enfermedades Cardiovasculares en Nariño",
    document_type: "CSV",
    size: "5.5 MB",
    dateUploaded: "hace 12 días",
  },
  {
    key: "6",
    name: "Movilidad y Migración Interna en Nariño: Un Estudio Demográfico",
    description:
      "Movilidad y Migración Interna en Nariño: Un Estudio Demográfico",
    document_type: "XLS",
    size: "6.5 MB",
    dateUploaded: "hace 15 días",
  },
];

const dataColumns = [
  {
    FECHA_CORTE: "2021/03/31",
    NOM_RPT:
      "REPORTE VICTIMAS ANUALIZADO, CIFRA NACIONAL (SEXO, ETNIA, DISCAPACIDAD, CICLO_VITAL)",
    VIGENCIA: 1988,
    SEXO: "Hombre",
    ETNICA: "Negro(a) o Afrocolombiano(a)",
    DISCAPACIDAD: "Por Establecer",
    CICLO_VITAL: "entre 29 y 60",
    PER_OCU: 17,
    EVENTOS: 2,
  },
  {
    FECHA_CORTE: "2021/03/31",
    NOM_RPT:
      "REPORTE VICTIMAS ANUALIZADO, CIFRA NACIONAL (SEXO, ETNIA, DISCAPACIDAD, CICLO_VITAL)",
    VIGENCIA: 1988,
    SEXO: "Intersexual",
    ETNICA: "Negro(a) o Afrocolombiano(a)",
    DISCAPACIDAD: "Intelectual",
    CICLO_VITAL: "entre 29 y 60",
    PER_OCU: 1,
    EVENTOS: 3,
  },
  {
    FECHA_CORTE: "2021/03/31",
    NOM_RPT:
      "REPORTE VICTIMAS ANUALIZADO, CIFRA NACIONAL (SEXO, ETNIA, DISCAPACIDAD, CICLO_VITAL)",
    VIGENCIA: 1988,
    SEXO: "Hombre",
    ETNICA: "Indigena",
    DISCAPACIDAD: "Por Establecer",
    CICLO_VITAL: "entre 29 y 60",
    PER_OCU: 17,
    EVENTOS: 2,
  },
  {
    FECHA_CORTE: "2021/03/31",
    NOM_RPT:
      "REPORTE VICTIMAS ANUALIZADO, CIFRA NACIONAL (SEXO, ETNIA, DISCAPACIDAD, CICLO_VITAL)",
    VIGENCIA: 1988,
    SEXO: "Mujer",
    ETNICA: "Negro(a) o Afrocolombiano(a)",
    DISCAPACIDAD: "Ninguna",
    CICLO_VITAL: "entre 29 y 60",
    PER_OCU: 1,
    EVENTOS: 1,
  },
  {
    FECHA_CORTE: "2021/03/31",
    NOM_RPT:
      "REPORTE VICTIMAS ANUALIZADO, CIFRA NACIONAL (SEXO, ETNIA, DISCAPACIDAD, CICLO_VITAL)",
    VIGENCIA: 1988,
    SEXO: "Intersexual",
    ETNICA: "Negro(a) o Afrocolombiano(a)",
    DISCAPACIDAD: "Por Establecer",
    CICLO_VITAL: "entre 29 y 60",
    PER_OCU: 17,
    EVENTOS: 19,
  },
];

const columnDescription = [
  {
    name: "FECHA_CORTE",
    dataType: "Fecha",
    description: "Fecha de corte de los datos, en formato AAAA/MM/DD.",
  },
  {
    name: "NOM_RPT",
    dataType: "Texto",
    description:
      "Nombre del reporte, describiendo el contenido y el alcance del mismo.",
  },
  {
    name: "VIGENCIA",
    dataType: "Entero",
    description: "Año de vigencia de los datos.",
  },
  {
    name: "SEXO",
    dataType: "Texto",
    description:
      "Género de las víctimas, puede ser Hombre, Mujer, Intersexual, etc.",
  },
  {
    name: "ETNICA",
    dataType: "Texto",
    description:
      "Grupo étnico al que pertenece la víctima, como Negro(a) o Afrocolombiano(a), Indígena, etc.",
  },
  {
    name: "DISCAPACIDAD",
    dataType: "Texto",
    description: "Tipo de discapacidad de la víctima, si aplica.",
  },
  {
    name: "CICLO_VITAL",
    dataType: "Texto",
    description: "Rango de edad de la víctima, como 'entre 29 y 60'.",
  },
  {
    name: "PER_OCU",
    dataType: "Entero",
    description:
      "Número de personas ocupadas en relación con el evento reportado.",
  },
  {
    name: "EVENTOS",
    dataType: "Entero",
    description: "Número de eventos reportados en el período de vigencia.",
  },
];

// #region Advertisements
const getDataManagement = (pageNumber = 1, pageSize = 10, search = "") =>
  // eslint-disable-next-line no-console
  console.log(pageNumber, pageSize, search);
// const url = `/web/v1/dataManagement/advertising?page[size]=${pageSize}&page[number]=${pageNumber}${search}`;
// eslint-disable-next-line no-console
console.log(dataSource);
const getDepartment = () => {
  const url =
    "https://www.datos.gov.co/resource/xdk5-pm3f.json?$query=SELECT%20%60c_digo_dane_del_departamento%60%2C%20%60departamento%60%0AGROUP%20BY%20%60c_digo_dane_del_departamento%60%2C%20%60departamento%60%0AORDER%20BY%20%60departamento%60%20ASC%20NULL%20LAST";
  return client.get(url);
};

const getMunicipalities = (depto) => {
  const url = `https://www.datos.gov.co/resource/xdk5-pm3f.json?c_digo_dane_del_departamento=${depto}`;
  return client.get(url);
};

const getMunicipalitiesSubRegions = () => {
  const url="/files/subregion-municipalities";
  return api.get(url);
}
const getSubRegions =()=>{
  const url="/files/municipality-subregions";
  return api.get(url);
}

const getColumnDescription = (data) => {
  // eslint-disable-next-line no-console
  console.log(data);
  return columnDescription;
};

const getDataColumns = (data) => {
  // eslint-disable-next-line no-console
  console.log(data);
  return dataColumns;
};

// const getDataManagement = (pageNumber = 1, pageSize = 10, search="") => {

// const createDataManagement = (data) => {
//   // const url = "/web/v1/dataManagement/advertising";
//   return true;
// };

// const updateDataManagement = (data) => {
//   // const url = "/web/v1/dataManagement/advertising/edit";
//   return true;
// };

// const deleteDataManagement = (data) => {
//   // const url = "/web/v1/dataManagement/advertising/delete";
//   return true;
// };

export default {
  getDataManagement,
  getDepartment,
  getMunicipalities,
  getColumnDescription,
  getDataColumns,
  getSubRegions,
  getMunicipalitiesSubRegions,
};
