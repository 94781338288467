/* eslint-disable react/jsx-boolean-value */
import "./App.css";

import "./index.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { ConfigProvider } from "antd";
// import Home from "./views/Home";
// styles for table
import { inputStyle, tableStyle } from "./assets/theme/themeConfig";

// componets
import ROUTES from "./constant/router";
import LoginPage from "./views/Login";
import Register from "./views/User/UserCreate";
import UpdateUserPage from "./views/User/UserUpdate";
import Home from "./views/Home";
import Report from "./views/Report";
import Statement from "./views/Statement";
import ViewInvestigation from "./views/Investigation/viewInvestigation";
import DocumentManagement from "./views/DocumentManagement";
// import DocumentRegister from "./views/DocumentManagement/Register";
// import Indicator from "./views/Indicator";
import IndicatorRegister from "./views/Indicator/Register";
import VariableRegister from "./views/VaribleIndicator/RegisterVariable";
import Variables from "./views/VaribleIndicator/ListVariables";
// import DocumentRegister from "./views/Document/Register";
// import DocumentDescription from "./views/Document/Description";
import ColumnDocument from "./views/ColumnDocument";
import Footer from "./components/organisms/Footer/Footer";
import UsersList from "./components/templates/UserTemplate/UsersList";
import RequireAuth from "./routers/RequireAuth";
import NotRequireAuth from "./routers/NotRequireAuth";
import RecoveryPage from "./views/Login/RecoveryPage";
import StatementIndicators from "./views/StatementIndicators";
import IndicatorsAdmin from "./views/Indicator/IndicatorsAdmin";
import PermissionRoute from "./routers/PermissionRoute";
import AdminInvestigation from "./views/Investigation/AdminInvestigation";
import Investigation from "./views/Investigation/Investigation";
import ComparativeAnalysis from "./views/ComparativeAnalysis";
import LandingPage from "./views/LandigPage";
import useTokenMonitor from "./hooks/useTokenMonitor";

// import RegisterUserPage from "./views/Register";

const App = () => {
  useTokenMonitor();
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
          colorPrimary: "#4cacbc",
          colorBgBase: "#f0f0f0",
        },
        font: {
          family: "Poppins, sans-serif",
        },

        components: {
          Table: tableStyle,
          Input: inputStyle,
        },
      }}
    >
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route
          exact
          path={ROUTES.GENERAL.HOME}
          element={
            <RequireAuth>
              <PermissionRoute>
                <Home />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.REPORT}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="report">
                <Report />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.STATEMENT}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="statement">
                <Statement />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.STATEMENT_INDICATORS}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="indicator.readreport">
                <StatementIndicators />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.INVESTIGATION_ADMIN}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="research.read">
                <AdminInvestigation />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.INVESTIGATION}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="researchpublished.read">
                <Investigation />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.INVESTIGATION_INFO}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="research.read">
                <ViewInvestigation />
              </PermissionRoute>
            </RequireAuth>
          }
        />

        <Route
          exact
          path={ROUTES.GENERAL.DOCUMENTS}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="document_management">
                <DocumentManagement />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        {/* <Route
          exact
          path={ROUTES.GENERAL.DOCUMENT_UPLOAD}
          element={
            <RequireAuth>
              <DocumentRegister />
            </RequireAuth>
          }
        /> */}
        <Route
          exact
          path={ROUTES.GENERAL.DOCUMENT_INFO}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="document_management">
                <ColumnDocument />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.INDICATORS}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="indicator.read">
                <IndicatorsAdmin />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.INDICATORS_CREATE}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="indicator.create">
                <IndicatorRegister />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.INDICATORS_EDIT}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="indicator.edit">
                <IndicatorRegister isEdit={true} />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.INDICATORS_VARIABLE_CREATE}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="indicator.create">
                <VariableRegister />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.INDICATORS_VARIABLE_EDIT}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="indicator.edit">
                <VariableRegister isEdit={true} />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.INDICATORS_VARIABLE}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="indicator">
                <Variables />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.COMPARATIVE_ANALYSIS}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="comparative_analysis">
                <ComparativeAnalysis />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.COMPARATIVE_ANALYSIS_DETAIL}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="comparative_analysis">
                <ComparativeAnalysis />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.USER_EDIT}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="userown">
                <UpdateUserPage />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.GENERAL.USERS_LIST}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="user.read">
                <UsersList />
              </PermissionRoute>
            </RequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.AUTH.USER_CREATE}
          element={
            <NotRequireAuth>
              <Register />
            </NotRequireAuth>
          }
        />
        <Route
          exact
          path={ROUTES.AUTH.LOGIN}
          element={
            <NotRequireAuth>
              <LoginPage />
            </NotRequireAuth>
          }
        />
        <Route exact path={ROUTES.HOME.LANDINGPAGE} element={<LandingPage />} />
        <Route exact path={ROUTES.AUTH.RECOVERY} element={<RecoveryPage />} />
        {/* <Route
          path={ROUTES.AUTH.USER_CREATE}
          element={
            <RequireAuth>
              <PermissionRoute permissionToAccess="user.create">
                <Register />
              </PermissionRoute>
            </RequireAuth>
          }
        /> */}
      </Routes>
      <Footer />
    </ConfigProvider>
  );
};

export default App;
