import { Button } from "antd";
import React from "react";
/* eslint-disable react/jsx-props-no-spreading */

function AppButton({ className = "", onClick, title, style, ...props }) {
  const combinedClassName = `flex justify-center items-center whitespace-normal h-auto px-6 py-2 font-Poppins font-normal rounded-full shadow-lg text-white_custom ${className}`;
  return (
    <Button
      {...props}
      className={combinedClassName}
      type="primary"
      onClick={onClick}
      style={style}
    >
      {title}
    </Button>
  );
}

export default AppButton;
