import React from "react";
import { Table } from "antd";
import "../../../assets/css/stylesTable.css";

const AppTable = ({
  columns,
  data,
  enableScroll = false,
  onSelectRow,
  isClickRowActive,
  rowKey = "key",
  onlyView = false,
  breakWords = false,
  handleChange
}) => (
  <Table
    bordered
    tableLayout="fixed"
    className={`bg-white_custom font-Poppins ${isClickRowActive ? "table-pointer" : ""} ${onlyView ? "custom-row-style-view" : "custom-row-style"}`}
    dataSource={data}
    scroll={{
      x: enableScroll,
    }}
    columns={columns}
    rowKey={rowKey}
    pagination={false}
    rowClassName={`${onlyView ? "custom-row-style-view" : "custom-row-style"} ${breakWords ? "table-break-words" : ""}`}
    onRow={(record, rowIndex) => ({
      onClick: (event) => onSelectRow && onSelectRow(record, rowIndex, event),
    })}
    onChange={handleChange}
  />
);
export default AppTable;
