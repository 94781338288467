// App.js
import React, { useState, useEffect } from "react";

import { Button } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AppTitle from "../../atoms/Title/AppTitle";
import AppModal from "../../molecules/Modal/Modal";
import AppText from "../../atoms/Text/AppText";
import ROUTES from "../../../constant/router";
import TableTemplate from "../TableTemplate";

const dataSource = [
  {
    key: "1",
    nombre: "Tasa de Crecimiento Económico",
    columnaFuente: "PIB Anual",
    agregacion: "Conteo",
  },
  {
    key: "2",
    nombre: "Índice de Calidad del Aire",
    columnaFuente: "Mediciones de CO2",
    agregacion: "Suma",
  },
  {
    key: "3",
    nombre: "Tasa de Alfabetización",
    columnaFuente: "Censo Educativo",
    agregacion: "Promedio ",
  },
  {
    key: "4",
    nombre: "Indicador de Desempleo",
    columnaFuente: "Encuestas de Empleo",
    agregacion: "Mode",
  },
  {
    key: "5",
    nombre: "Consumo Energético",
    columnaFuente: "Registros de Energía",
    agregacion: "Ultimo Valor",
  },
  {
    key: "6",
    nombre: "Indicador de Salud Pública",
    columnaFuente: "Reportes Hospitalarios",
    agregacion: "Conteo",
  },
];

const VariableListTemplate = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const total = dataSource.length;
  const [modalActive, setModalActive] = useState(false);

  const [data, setData] = useState(dataSource);
  const navigate = useNavigate();

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    setData(dataSource);
  }, [currentPage]);

  const handlePageChange = (page) => {
    // hacer la petición a la API para obtener los datos de la página
    setCurrentPage(page);
  };

  const closeModal = () => {
    setModalActive(false);
  };

  const openModal = () => {
    setModalActive(true);
  };

  const onDelete = (key) => {
    // eslint-disable-next-line no-console
    console.log(key);
    openModal();
  };
  const onEdit = (key) => {
    const path = ROUTES.GENERAL.INDICATORS_VARIABLE_EDIT.replace(":id", key);
    navigate(path);
  };

  const handleDelete = () => {
    closeModal();
  };

  // Las columnas de la tabla
  const columns = [
    {
      title: (
        <span className="font-medium text-center text-xl">
          Variable del Indicador
        </span>
      ),
      dataIndex: "nombre", // Asegúrate de que los datos tengan una clave 'nombre'
      key: "nombre",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">{text}</span>
      ),
    },
    {
      title: (
        <span className="font-medium text-center text-xl">Columna Fuente</span>
      ),
      dataIndex: "columnaFuente", // Cambiado de 'area' a 'columnaFuente' para mayor claridad
      key: "columnaFuente",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: (
        <span className="font-medium text-center text-xl">Agregación</span>
      ),
      dataIndex: "agregacion", // Cambiado de 'descripcion' a 'agregacion'
      key: "agregacion",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">{text}</span>
      ),
      className: "border-b-0",
    },
    {
      title: <span className="font-medium text-center text-xl">Acciones</span>,
      key: "acciones",
      align: "center",
      render: (text, record) => (
        <div className="flex space-x-2 justify-center">
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none"
            icon={<EditOutlined />}
            onClick={() => onEdit(record.key)}
          />
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none"
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record.key)}
          />
        </div>
      ),
      className: "border-b-0",
    },
  ];

  const handlePageForm = () => {
    navigate(ROUTES.GENERAL.INDICATORS_VARIABLE_CREATE);
  };

  return (
    <div className="min-h-screen bg-white_custom flex flex-col items-center pt-10 ">
      <div className="w-3/4 mb-2 mr-6 grid justify-items-end ">
        <Button
          shape="round"
          size="large"
          type="primary"
          className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
          onClick={handlePageForm}
        >
          Añadir nueva variable
        </Button>
      </div>
      <div className="w-3/4 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
        <div className="flex justify-center items-center mb-4">
          <AppTitle
            title="Variable de Indicadores cargados"
            level={4}
            className="text-lightBlue2 font-bold  text-center !important"
          />
        </div>
        <TableTemplate
          enableScroll
          data={data}
          handlePageChange={handlePageChange}
          total={total}
          columns={columns}
          pageSize={pageSize}
          currentPage={currentPage}
          className=" "
        />
        <AppModal
          title="Eliminar Variable"
          open={modalActive}
          onCancel={closeModal}
          // eslint-disable-next-line react/jsx-boolean-value
          showFooter={true}
          className=" text-lightBlue2 font-normal text-lg text-center !important"
          onOk={() => handleDelete()}
          ok="Eliminar"
          cancel="Cancelar"
          okType="danger"
          icon={<ExclamationCircleFilled />}
        >
          <AppText title="¿Está seguro de eliminar la variable?" />
        </AppModal>
      </div>
    </div>
  );
};
export default VariableListTemplate;
