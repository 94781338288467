import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Skeleton } from "antd";
import { useAuth } from "../contexts/AuthContext";
import findElementInArray from "../helpers/findElementInArray";

const PermissionRoute = ({ permissionToAccess = "", children }) => {
  const valuesAuth = useAuth();
  const [loadingPermissions, setLoadingPermissions] = useState(true);

  useEffect(() => {
    if (
      Array.isArray(valuesAuth.permissions) &&
      valuesAuth.permissions.length > 0
    ) {
      setLoadingPermissions(false);
    } else {
      setLoadingPermissions(true);
    }
  }, [valuesAuth.permissions]);

  const renderRoute = () => {
    if (!loadingPermissions) {
      if (findElementInArray(valuesAuth.permissions, permissionToAccess) || !permissionToAccess)
        return children;
      return <Navigate replace to="/" />;
    }
    return (
      <div className="flex flex-col justify-center items-center mx-10">
        <Skeleton active/>
        <Skeleton active className="mt-10"/>
      </div>
    );
  };
  return renderRoute();
};

export default PermissionRoute;
