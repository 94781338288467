import React, {useState} from "react";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import Step from "../../molecules/Step/Step";
// import DocumentRegister from "../../../views/DocumentManagement/Register";
import DocumentFormTemplate from "../../templates/DocumentTemplate/DocumentFormTemplate";
import DocumentUploadForm from "./DocumentUploadForm";

const DocumentUploadSteps = ({
  current,
  onClickNext,
  docTypes,
  loadingSteps,
  action,
  listCodification,
  handlerSubmit,
  docTypeWeb,
  iswebSource,

}) => {
  const location = useLocation();
  const { pathname } = location;
  const items = [
    {
      title: "Importar datos",
    },
    {
      title: "Descripción documento",
    },
  ];
  const [urlSource, setUrlSource] =useState("");

  const renderStepForm = () => {
    if (current === 0)
      return !loadingSteps ? (
        <DocumentUploadForm
          onClickNext={onClickNext}
          options={docTypes}
          pathname={pathname}
          docTypeWeb={docTypeWeb}
          setUrlSource={setUrlSource}
        />
      ) : (
        <div className="flex justify-center items-center h-60">
          <Spin />
        </div>
      );

    return (
      <DocumentFormTemplate
        listCodification={listCodification}
        documentKey={1}
        action={action}
        handlerSubmit={handlerSubmit}
        webSource={iswebSource}
        urlSource={urlSource}
      />
    );
  };

  return (
    <div className="flex flex-col items-center mt-5">
      <Step items={items} current={current} />
      <div className="mt-4 text-lg font-bold">
        {current === 0 ? "Importar Datos" : "Descripción del Documento"}
      </div>
      {renderStepForm()}
    </div>
  );
};

export default DocumentUploadSteps;
