import { Layout } from "antd";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import VariableFormTemplate from "../../components/templates/VariableTemplate/VariableFormTemplate";

const { Content } = Layout;

const ResponseData = {
  fontColumn: "tasaIncidentes",
  name: "Tasa de Incidentes",
  aggregation: "1",
};

const VariableRegister = ({ isEdit = false }) => {
  const { id } = useParams();
  const [dataEdit] = useState(ResponseData);
  const listColumns = [
    {
      value: "tasaIncidentes",
      label: "Tasa de Incidentes",
    },
    {
      value: "edadPromedioVictimas",
      label: "Edad Promedio de Víctimas",
    },
    {
      value: "porcentajeGeneroFemenino",
      label: "Porcentaje de Género Femenino",
    },
    {
      value: "numeroDenuncias",
      label: "Número de Denuncias",
    },
    {
      value: "tasaResolucionCasos",
      label: "Tasa de Resolución de Casos",
    },
  ];
  const listAgregation = [
    {
      value: "1",
      label: "SUMA",
    },
    {
      value: "2",
      label: "Lista",
    },
    {
      value: "3",
      label: "Media",
    },
    {
      value: "4",
      label: "Mediana",
    },
    {
      value: "5",
      label: "Moda",
    },
    {
      value: "6",
      label: "Primero",
    },
    {
      value: "7",
      label: "Último",
    },
    {
      value: "8",
      label: "Conteo",
    },
    {
      value: "9",
      label: "GINI",
    },
  ];
  useEffect(() => {
    if (isEdit) {
      // llamar a la api para editar
    } else {
      // llamar a la api para registrar
    }
  }, [isEdit, id, dataEdit]);
  return (
    <Layout>
      <Content>
        <VariableFormTemplate
          listColumns={listColumns}
          listAgregation={listAgregation}
          isEdit={isEdit}
          dataEdit={dataEdit}
        />
      </Content>
    </Layout>
  );
};

export default VariableRegister;
