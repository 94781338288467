import React from "react";
import { Form } from "antd";
// import FormDateRange from "../../molecules/FormDate/FormDateRange";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import FormTreeSelect from "../../molecules/FormTree/FormTreeSelect";
import SubmitButton from "../../atoms/Buttons/SubmitButton";

const FiltersStatementIndicatorsForm = ({
  handleValues,
  dataSelectors,
  handleRequestIndicators,
  handleRequestMunicipalities,
  handleRequestRegion,
  loading,
}) => {
  const [form] = Form.useForm();

  const handleChangeGroup = (val) => {
    handleRequestIndicators(val);
    form.resetFields(["indicators"]);
  };

  const handleChangeIndicators = (val) => {
    handleRequestMunicipalities(val);
    handleRequestRegion(val);
    form.resetFields(["municipalities"]);
  };


  return (
    <Form
      form={form}
      onFinish={handleValues}
      className="flex flex-col w-full justify-items-center pr-5"
    >
      {/* Dropdown for selecting a group indicator */}
      <div className="flex flex-col items-center w-full text-center mb-3">
        <div className="text-sm mb-1 italic font-light">
          Seleccione un grupo de indicador:
        </div>
        <FormSelect
          name="group_indicator"
          className="font-Poppins text-black_custom formhome w-full text-center"
          selectClass="formBgWhite"
          placeholder="Seleccione una opción"
          onChange={(val) => handleChangeGroup(val)}
          options={dataSelectors.groupIndicators}
          rules={[
            {
              required: true,
              message: "Por favor seleccione una opción",
            },
          ]}
        />
      </div>
      {/* Dropdown for selecting an indicator */}
      <div className="flex flex-col items-center w-full text-center mb-3">
        <div className="text-sm mb-1 italic font-light">
          Seleccione un indicador:
        </div>
        <FormSelect
          name="indicators"
          className="font-Poppins text-black_custom formhome w-full text-center"
          selectClass="formBgWhite"
          placeholder="Seleccione una opción"
          options={dataSelectors.indicatorsByGroup}
          rules={[
            {
              required: true,
              message: "Por favor seleccione una opción",
            },
          ]}
          onChange={(val) => handleChangeIndicators(val)}
          loading={loading.indicatorsByGroup}
        />
      </div>
      {/* Conditionally render the municipality/region select */}

      <div className="flex flex-col items-center w-full text-center mb-3">
        <div className="text-sm mb-1 italic font-light">
          Seleccione una ubicación:
        </div>
        <FormTreeSelect
          name="municipalities"
          styleItem="font-Poppins text-black_custom formhome w-full text-center"
          className ="formBgWhite"
          placeholder="Seleccione una opción"
          treeData={dataSelectors.municipalitiesByIndicator}
          maxTagCount={1}
          rules={[
            {
              required: true,
              message: "Por favor seleccione una opción",
            },
          ]}
          loading={loading.municipalitiesByIndicator}
        />
      </div>

      <SubmitButton
        className="bg-darkAquamarineBlue text-white_custom rounded-full w-full h-10 mt-2 md:mt-10"
        nameButton="Aplicar"
        htmlType="submit"
        form={form}
        loading={loading.chart}
      />
    </Form>
  );
};

export default FiltersStatementIndicatorsForm;
