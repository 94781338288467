import React from "react";
import { ConfigProvider } from "antd";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";
import FormSelect from "../../molecules/FormSelect/FormSelect";
import FormInput from "../../molecules/FormInput/FormInput";
import AppText from "../../atoms/Text/AppText";


function IndicadorForm({ listType, listVariable }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          "Select": selectWhiteStyle,
        },
      }}
    >


      <div className="grid gap-4 mb-8grid-cols-1 md:grid-cols-2 divide-x  divide-colorBorder2/25 py-4">
        <div className="mb-8 flex flex-col">
          <div className="p-6">

            <FormInput
              name="name"
              className="text-color_text"
              label="Nombre :"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Por favor ingrese el nombre" }]}

            />


          </div>
          <div className="p-6">
            <FormSelect
              label="Tipo :"
              name="type"
              className="font-semibold text-color_text text-textTable"
              selectClass="formBgWhite "
              // popupClassName="font-normal "
              labelCol={{ span: 24 }}
              options={listType}
              rules={[{ required: true, message: "Por favor seleccione una opción" }]}
            />
          </div>

        </div >
        <div className="border-none md:border-solid md:border-l-2  px-6 flex flex-col">
          <div className="py-2 px-4">
            <AppText
              title="Variables:"
              className="font-normal text-color_text text-textTable"
            />
          </div>

          <div className=" grid gap-4 mb-8grid-cols-1 sm:grid-cols-2 md:grid-cols-2">
            <FormSelect

              name="variable1"
              className="font-semibold text-color_text text-textTable"
              selectClass="formBgWhite "
              // popupClassName="font-normal "
              labelCol={{ span: 24 }}
              options={listVariable}
            />
            <FormSelect

              name="variable2"
              className="font-semibold text-color_text text-textTable"
              selectClass="formBgWhite "
              // popupClassName="font-normal "
              labelCol={{ span: 24 }}
              options={listVariable}
            />
            <FormSelect

              name="variable3"
              className="font-semibold text-color_text text-textTable"
              selectClass="formBgWhite "
              // popupClassName="font-normal "
              labelCol={{ span: 24 }}
              options={listVariable}
            />
            <FormSelect

              name="variable4"
              className="font-semibold text-color_text text-textTable"
              selectClass="formBgWhite "
              // popupClassName="font-normal "
              labelCol={{ span: 24 }}
              options={listVariable}
            />

          </div>
          <div className="py-2 px-4">
            <AppText
              title="Fórmula:"
              className="font-normal text-color_text text-textTable"
            />
          </div>
          <div className="px-4">
            <FormInput
              className="text-color_text"
              name="numerator"
              label="Numerador :"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Por favor ingrese el numerador" }]}
            />
          </div>
          <div className="px-4">
            <FormInput
              name="denominator"
              className="text-color_text"
              label="Denominador :"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Por favor ingrese el denominador" }]}
            />
          </div>
        </div>

      </div>
    </ConfigProvider>

  );
}

export default IndicadorForm;
