/* eslint-disable arrow-body-style */
import React from "react";
import { ConfigProvider, Divider } from "antd";
import { selectWhiteStyle } from "../../../assets/theme/themeConfig";
import FormInput from "../../molecules/FormInput/FormInput";
import FormDate from "../../molecules/FormDate/FormDate";
import FormTextArea from "../../molecules/FormTextArea/FormTextArea";
import FormTreeSelect from "../../molecules/FormTree/FormTreeSelect";
import AppImgUpload from "../../molecules/Upload/AppImgUpload";

const InvestigationDescriptionForm = ({
  validatorDate,
  listMunicipalities = [],
  defaultImageUrl = null,
  settingFileList,
}) => {
  
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: selectWhiteStyle,
        },
      }}
    >
      <div className="flex flex-col md:flex-row mb-5">
        <div className="w-full md:w-1/2">
          <FormInput
            name="title"
            label="Título:"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Este campo es requerido",
              },
            ]}
          />
          <FormInput
            name="author"
            label="Autor:"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Este campo es requerido",
              },
            ]}
          />
          <FormDate
            name="date"
            label="Seleccione una fecha:"
            className=" text-color_text"
            rules={validatorDate()}
            labelCol={{ span: 24 }}
          />
          <FormTreeSelect
            treeData={listMunicipalities}
            placeholder="Selecciona tus opciones"
            maxTagCount={2}
            name="location"
            label="Ubicación Municipios:"
            labelCol={{ span: 24 }}
            className="treeMunicipality"
            rules={[
              {
                required: true,
                message: "Este campo es requerido",
              },
            ]}
          />
        </div>
        <Divider
          type="vertical"
          className="h-auto border border-gray_custom hidden md:block"
        />
        <div className="w-full md:w-1/2">
          <FormTextArea
            name="abstract"
            className="!text-color_text font-light text-sizeBase"
            classText="h-[105px]"
            label="Resumen:"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Este campo es requerido",
              },
            ]}
          />
          <div className="flex flex-col">
            <p className="text-left text-text_gray font-light">Imagen:</p>
            <AppImgUpload
              name="imageUrl"
              title="Subir"
              className="text-center"
              defaultImageUrl={defaultImageUrl || null}
              settingFileList={settingFileList}
            />
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default InvestigationDescriptionForm;
