import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import { useParams } from "react-router-dom";
import DataPreviewTemplate from "../../components/templates/DataPreviewTemplate/DataPreviewTemplate";
import ShareFileTemplate from "../../components/templates/ShareFileTemplate/ShareFileTemplate";
import document from "../../api/document";
import useApi from "../../api/useApi";
import { useAuth } from "../../contexts/AuthContext";
import findElementInArray from "../../helpers/findElementInArray";
import GeneralInformation from "../../components/templates/GeneralInformation/GeneralInformation";

// import validatePermissionUserOwner from "../../helpers/validatePermissionOwnerUser";

const TabsDocument = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const getDescriptionDoc = useApi(document.getDescriptionDoc);
  const getParentDoc = useApi(document.getDescriptionDoc);
  const [isWebSource, setIsWebSource] = useState(false);
  const [idParent, setIdParent] = useState();
  const [dataDescription, setDataDescription] = useState({});
  const [title, setTitle] = useState();
  const [itemDefault, setItemDefault] = useState("1");
  // const { IsOwner } = validatePermissionUserOwner;

  const getInfoDoc = () => {
    getDescriptionDoc.request(params?.key);
  };

  useEffect(() => {
    getInfoDoc();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (getDescriptionDoc.data) {
      const aux = getDescriptionDoc.data;
      let auxId = params?.key;
      if (aux.isChild) {
        auxId = aux.parentId;
        getParentDoc.request(aux.parentId);
      }else{
        setTitle(aux.name);
      }
      setTitle(aux.name);
      if (aux.multipleSheets){
        if (aux.urlPages.length > 0){
          setItemDefault("2");
        }
      }
      setIdParent(auxId);
      setLoading(false);
      setDataDescription(aux);
      if (aux.fileExtension === "web") {
        setIsWebSource(true);
      } else {
        setIsWebSource(false);
      }
      getDescriptionDoc.setData();
    }
    if (getParentDoc.data) {
      setTitle(getParentDoc.data.name);
      getParentDoc.setData();
    }
    
  }, [getDescriptionDoc.data, getParentDoc.data]);

  useEffect(() => {
    if (getParentDoc.error) {
      setTitle("Documento no encontrado");
      getParentDoc.setData();
    }
    // if (getDescriptionDoc.error) {
    //   // setDescription("Documento no encontrado");
    //   // setDescriptionDoc.setData();
      
    // }

  }, [getParentDoc.error, getDescriptionDoc.error]);

  const { permissions } = useAuth();
  const hasPermissionShare = findElementInArray(
    permissions,
    "document_management.share"
  );
  const items = [
    {
      key: "1",
      label: "Vista previa",
      children: (
        <div className="shadow-body ml-[3px] relative top-[-1px]">
          <DataPreviewTemplate dataInfo={dataDescription}  />
        </div>
      ),
      destroyInactiveTabPane: !!dataDescription.multipleSheets,
    },
    // {
    //   key: "3",
    //   label: "Linaje",
    //   children: (
    //     <div className="shadow-body ml-[3px] relative top-[-1px]">
    //       Content of Tab Pane 3
    //     </div>
    //   ),
    // },
    {
      key: "2",
      label: "Información general",
      children: (
        <div className="shadow-body ml-[3px] relative top-[-1px]">
          <GeneralInformation idDoc={idParent} />
        </div>
      ),
    },
    {
      key: "3",
      label: "Compartir",
      children: (
        <div className="shadow-body ml-[3px] relative top-[-1px]">
          <ShareFileTemplate
            infoFile={dataDescription}
            isWebSource={isWebSource}
          />
        </div>
      ),
    },
  ];

  // const listItems = isWebSource ? items : [...items, ...auxItem];

  //   const onChange = (key) => {
  //   console.log(key);
  // };
  // },
  // {
  //   key: "3",
  //   label: "Linaje",
  //   children: (
  //     <div className="shadow-body ml-[3px] relative top-[-1px]">
  //       Content of Tab Pane 3
  //     </div>
  //   ),
  // },

  const validateAuxItems = () => {
    if (hasPermissionShare) {
      if (dataDescription.isOwner) return items;
      return items.filter((item) => item.key !== "3");
    }
    return items.filter((item) => item.key !== "3");
  };

  return !loading ? (
    <div className="min-h-screen flex flex-col pt-10 px-2 md:px-20">
      <div className="shadow-body px-5 pb-5 relative rounded-large">
        <div className="flex w-full justify-center items-center py-5">
          <strong className="text-lightBlue2 text-2xl">
            {/* {dataDescription?.name} */}
            {title}
          </strong>
        </div>
        <Tabs type="card" defaultActiveKey={itemDefault} items={validateAuxItems()} />
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-60">
      <Spin />
    </div>
  );
};

export default TabsDocument;
